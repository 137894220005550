<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn
            class="mr-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Edit Role
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.name"
                outlined
                label="Name"
                prepend-inner-icon="mdi-card-text-outline"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-switch
                class="mt-0 ml-5"
                v-model="form.is_active"
                inset
                :label="`Is Active: ${form.is_active ? 'YES' : 'NO'}`"
              ></v-switch>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <section v-for="(module, m) in moduleItems" :key="`module${m}`">
            <v-row>
              <v-col>
                <h3 class="my-4">
                  List of Permission(s) for {{ module.name }}:
                </h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                md="2"
                v-for="(permission, p) in module.permissions"
                :key="`permission${p}`"
              >
                <v-checkbox
                  v-model="selected"
                  :value="permission.id + '_M' + module.id"
                  @click="UpdatePermission(permission.id)"
                  :label="`${permission.permission_name}`"
                ></v-checkbox
              ></v-col>
            </v-row>
            <v-divider class="my-5"> </v-divider>
          </section>
          <v-btn
            rounded
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="save"
          >
            Update Role
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";

export default {
  name: "RoleEditPage",
  data() {
    return {
      form: {
        is_active: false,
        role_permissions: [],
      },
      isDeletePermissionDialogs: [],
      permissionItems: [],
      moduleItems: [],
      selected: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`roles/masters`);
      masters = masters.data;
      //Permission
      masters.permissions.forEach((permission) => {
        this.permissionItems.push({
          id: permission.id,
          text: permission.module.name + " - " + permission.permission_name,
          value: permission.id,
        });
      });
      //Module
      this.moduleItems = masters.modules;
    },
    async UpdatePermission(permission_id) {
      // Assign or unassign permission to role
      let permission_payload = {
        permission_id: permission_id,
      };
      if (permission_id) {
        let result = this.form.role_permissions.find(
          (pp) => pp.permission_id == permission_id
        );
        if (result) {
          this.form.role_permissions.splice(
            this.form.role_permissions.indexOf(result),
            1
          );
        } else {
          this.form.role_permissions.push(permission_payload);
        }
      }
    },
    async getData() {
      this.isLoading = true;
      let encryptedId = this.$route.params.roleId;
      axios
        .get(`/roles/${encryptedId}`)
        .then((response) => {
          this.form = response.data.data;
          this.old_json = response.data.data;
          this.form.role_permissions.forEach((role) => {
            this.selected.push(
              role.permission_id + "_M" + role.permission.module_id
            );
          });
        })
        .catch((error) => {
          // redirect to Home Page
          window.location.href = "/auth/login";
          console.error("Error fetching data:", error);
        });
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.post(`/roles`, this.form);
        this.isLoading = false;
        await this.saveUserTimestamp(this.old_json, this.form);
        this.$router.push("/roles");
      } catch (e) {
        this.isLoading = false;
      }
    },
    Add_Empty_Permission() {
      let role_permission = {
        permission_id: "",
        description: "",
      };
      this.form.role_permissions.push(role_permission);
    },
    deletePermission(role_permission) {
      this.form.role_permissions.splice(
        this.form.role_permissions.indexOf(role_permission),
        1
      );
      this.closePermissionDialogs();
    },
  },
};
</script>
