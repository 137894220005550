import { mapGetters } from "vuex";
import axios from "axios";
import Vue from "vue";

const mixin = {
  install(Vue) {
    Vue.mixin({
      methods: {
        // Define your universal function
        encryptIt(string) {
          const CryptoJS = require("crypto-js");
          const secretKey = CryptoJS.enc.Utf8.parse(
            "12345678123456781234567812345678"
          );
          const iv = CryptoJS.enc.Utf8.parse("Ef7ix7ETPgghl3vP");
          let encrypted = CryptoJS.AES.encrypt(
            CryptoJS.enc.Utf8.parse(string),
            secretKey,
            { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
          ).toString();
          return encrypted.replace(/[+]/g, "-").replace(/[/]/g, "_");
        },
        decryptIt(string) {
          const CryptoJS = require("crypto-js");
          const secretKey = CryptoJS.enc.Utf8.parse(
            "12345678123456781234567812345678"
          );
          const iv = CryptoJS.enc.Utf8.parse("Ef7ix7ETPgghl3vP");
          string = string.replace(/[-]/g, "+").replace(/[_]/g, "/");
          let encrypted = CryptoJS.AES.decrypt(string, secretKey, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
          }).toString(CryptoJS.enc.Utf8);
          return encrypted;
        },
        async handleFileUpload(id, attachment_key, attachment, path) {
          console.log("Handle Function called");
          if (attachment != null) {
            console.log("uploading");
            // let attachment = this.$refs.delivery_flow_file.files[0];
            let formData = new FormData();
            formData.append("id", id);
            formData.append(attachment_key, attachment);
            await axios
              .post(path, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then(() => {
                console.log("Uploaded successfully");
              })
              .catch(function (error) {
                console.log(error);
                console.log("FAILURE!!");
              });
          }
        },
        async saveUserTimestamp(old_json, new_json) {
          try {
            let form = {
              from_path: this.$route.path,
              from_name: this.$route.name,
              user_id: this.user.id,
              old_json: old_json ? JSON.stringify(old_json) : null,
              new_json: JSON.stringify(new_json),
            };
            await axios.post(`/user_timestamps`, form);
          } catch (error) {
            console.log(error);
          }
        },
        isDeletePage() {
          let response = false;
          if (this.$route.query.open_trash) {
            response = true;
          }
          return response;
        },
      },
      computed: {
        ...mapGetters({
          authenticated: "auth/authenticated",
          user: "auth/user",
          company: "auth/company",
          errors: "errors/getErrors",
          status: "errors/getStatus",
          baseUrl: "settings/getBaseUrl",
          mediaUrl: "settings/getMediaUrl",
          rowsPerPage: "settings/getRowsPerPage",
          roleName: "auth/roleName",
          positionName: "auth/positionName",
        }),
      },
    });
  },
};

Vue.use(mixin);
