<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn
            class="mr-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Edit Agent
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.name"
                outlined
                label="Name *"
                prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.email"
                outlined
                label="Email *"
                prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.email"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="country"
                outlined
                v-model="form.country_id"
                :items="countryItems"
                label="Country *"
                prepend-inner-icon="mdi-earth"
                clearable
                :error-messages="errors.country_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.primary_contact_no"
                outlined
                label="Primary Contact No"
                prepend-inner-icon="mdi-card-text-outline"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.secondary_contact_no"
                outlined
                label="Secondary Contact No"
                prepend-inner-icon="mdi-card-text-outline"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.address"
                outlined
                label="Address"
                prepend-inner-icon="mdi-card-text-outline"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.pincode"
                outlined
                label="Pincode"
                prepend-inner-icon="mdi-card-text-outline"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.percent"
                outlined
                label="Agent Percent *"
                prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.percent"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="agent_type"
                outlined
                v-model="form.agent_type_id"
                :items="agentTypeItems"
                label="Agent Type *"
                prepend-inner-icon="mdi-earth"
                clearable
                :error-messages="errors.agent_type_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="account_group"
                outlined
                v-model="form.account_group_id"
                :items="accountGroupItems"
                label="Account Group"
                prepend-inner-icon="mdi-earth"
                clearable
                :error-messages="errors.account_group_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="status"
                outlined
                v-model="form.status"
                :items="approvalStatuses"
                label="Approval Status *"
                prepend-inner-icon="mdi-earth"
                :error-messages="errors.status"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.pan_no"
                outlined
                label="Pan Number*"
                prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.pan_no"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <label for="Pan">Upload Pan Card</label>
              <br />
              <input
                label="Pan Card"
                type="file"
                id="pan_file"
                name="pan_file"
                accept="image/*"
                :ref="`pan_file`"
                @change="onFileChange"
              />
              <v-btn
                x-small
                class="reset_image my-1"
                color="red"
                dark
                @click="($refs.pan_file.value = null), (pan_image_url = null)"
                >Remove</v-btn
              >

              <div id="preview" v-if="pan_image_url" class="mt-5">
                <label for="">Image Preview</label>
                <br />
                <img
                  :src="pan_image_url"
                  style="height: 30%; width: 30% !important"
                />
              </div>
            </v-col>
            <v-col sm="4">
              <label for="cancel_cheque">Upload Cancel Cheque</label>
              <br />
              <input
                type="file"
                id="cancel_cheque_file"
                name="cancel_cheque_file"
                accept="image/*"
                :ref="`cancel_cheque_file`"
                @change="onFileChange"
              />
              <v-btn
                x-small
                class="reset_image my-1"
                color="red"
                dark
                @click="
                  ($refs.cancel_cheque_file.value = null),
                    (cancel_cheque_image_url = null)
                "
                >Remove</v-btn
              >

              <div id="preview" v-if="cancel_cheque_image_url" class="mt-5">
                <label for="">Image Preview</label>
                <br />
                <img
                  :src="cancel_cheque_image_url"
                  style="height: 30%; width: 30% !important"
                />
              </div>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <section>
            <template v-if="form.agent_bank_details.length">
              <v-divider class="my-2"> </v-divider>
              <h3 class="my-4">List of Bank(s) for this Vendor:</h3>
              <v-row
                v-for="(bank_detail, vBD) in form.agent_bank_details"
                :key="`bank_detail${vBD}`"
              >
                <v-col sm="4">
                  <v-autocomplete
                    ref="bank_name"
                    outlined
                    dense
                    v-model="bank_detail.bank_name_id"
                    :items="bankNameItems"
                    label="Bank Names"
                    prepend-inner-icon="mdi-earth"
                    clearable
                    :error-messages="errors.bank_name_id"
                  ></v-autocomplete>
                </v-col>
                <v-col sm="4">
                  <v-text-field
                    v-model="bank_detail.account_no"
                    outlined
                    dense
                    label="Account No"
                    prepend-inner-icon="mdi-bank"
                    :error-messages="
                      errors[`agent_bank_details.${vBD}.account_no`]
                        ? errors[`agent_bank_details.${vBD}.account_no`]
                        : ``
                    "
                  ></v-text-field>
                </v-col>
                <v-col sm="4">
                  <v-autocomplete
                    ref="country"
                    outlined
                    dense
                    v-model="bank_detail.bank_country_id"
                    :items="countryItems"
                    label="Country"
                    prepend-inner-icon="mdi-earth"
                    clearable
                    @input="getCountryWiseState(bank_detail)"
                    :error-messages="errors.bank_country_id"
                  ></v-autocomplete>
                </v-col>
                <v-col sm="4">
                  <v-autocomplete
                    ref="state"
                    :disabled="!bank_detail.bank_country_id"
                    outlined
                    dense
                    v-model="bank_detail.bank_state_id"
                    :items="bank_detail.stateItems"
                    label="State"
                    prepend-inner-icon="mdi-earth"
                    clearable
                    @input="getStateWiseCity(bank_detail)"
                    :error-messages="errors.bank_state_id"
                  ></v-autocomplete>
                </v-col>
                <v-col sm="4">
                  <v-autocomplete
                    ref="city"
                    :disabled="!bank_detail.bank_state_id"
                    outlined
                    dense
                    v-model="bank_detail.bank_city_id"
                    :items="bank_detail.cityItems"
                    label="City"
                    prepend-inner-icon="mdi-earth"
                    clearable
                    :error-messages="errors.bank_city_id"
                  ></v-autocomplete>
                </v-col>
                <v-col sm="4">
                  <v-text-field
                    v-model="bank_detail.pincode"
                    outlined
                    dense
                    label="Pincode"
                    prepend-inner-icon="mdi-bank"
                    :error-messages="
                      errors[`agent_bank_details.${vBD}.pincode`]
                        ? errors[`agent_bank_details.${vBD}.pincode`]
                        : ``
                    "
                  ></v-text-field>
                </v-col>
                <v-col sm="8">
                  <v-textarea
                    outlined
                    rows="1"
                    dense
                    v-model="bank_detail.address"
                    label="Address"
                  ></v-textarea>
                </v-col>
                <v-col sm="4">
                  <v-text-field
                    v-model="bank_detail.phone_no"
                    outlined
                    dense
                    label="Phone No"
                    prepend-inner-icon="mdi-bank"
                    :error-messages="
                      errors[`agent_bank_details.${vBD}.phone_no`]
                        ? errors[`agent_bank_details.${vBD}.phone_no`]
                        : ``
                    "
                  ></v-text-field>
                </v-col>
                <v-col sm="4">
                  <v-text-field
                    v-model="bank_detail.ifsc_code"
                    outlined
                    dense
                    label="IFSC Code"
                    prepend-inner-icon="mdi-bank"
                    :error-messages="
                      errors[`agent_bank_details.${vBD}.ifsc_code`]
                        ? errors[`agent_bank_details.${vBD}.ifsc_code`]
                        : ``
                    "
                  ></v-text-field>
                </v-col>
                <v-col sm="1">
                  <v-dialog
                    v-model="isDeleteBankDetailDialogs[p]"
                    max-width="600px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        class="ml-9"
                        small
                        dark
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> mdi-close</v-icon>
                      </v-btn>
                    </template>
                    <v-card elevation="10">
                      <v-card-title>
                        <v-icon large color="primary">
                          mdi-information-outline
                        </v-icon>
                        &nbsp; Do you want to remove the section?
                      </v-card-title>
                      <v-card-text>
                        <v-btn
                          color="primary"
                          @click.stop="deleteBankDetail(bank_detail)"
                        >
                          Yes
                        </v-btn>
                        &nbsp;
                        <v-btn
                          color="red"
                          dark
                          @click.stop="isDeleteBankDetailDialogs = []"
                        >
                          No
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </template>
            <v-row>
              <v-col sm="12" class="mb-5">
                <v-btn
                  small
                  rounded
                  color="primary"
                  @click="Add_Empty_BankDetail"
                >
                  Add Bank Detail
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="mt-5 mb-5"> </v-divider>
          </section>
          <v-btn
            rounded
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="save"
          >
            Update Agent
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";

export default {
  name: "AgentEditPage",
  data() {
    return {
      form: {},
      pan_file: "",
      pan_image_url: "",
      cancel_cheque_file: "",
      cancel_cheque_image_url: "",
      approvalStatuses: [
        { id: "PENDING", text: "PENDING", value: 0 },
        { id: "APPROVED", text: "APPROVED", value: 1 },
        { id: "REJECTED", text: "REJECTED", value: 2 },
      ],
      bankNameItems: [],
      countryItems: [],
      stateItems: [],
      allstateItems: [],
      allcityItems: [],
      agentTypeItems: [],
      accountGroupItems: [],
      isDeleteBankDetailDialogs: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getMasters();
    this.getData();
  },
  methods: {
    async getMasters() {
      await axios
        .get(`agents/masters`)
        .then((masters) => {
          masters = masters.data;
          //Countries
          masters.countries.forEach((country) => {
            this.countryItems.push({
              id: country.id,
              text: country.description,
              value: country.id,
            });
          });
          //Bank Names
          masters.bank_names.forEach((bN) => {
            this.bankNameItems.push({
              id: bN.id,
              text: bN.description,
              value: bN.id,
            });
          });
          //State
          masters.states.forEach((state) => {
            this.stateItems.push({
              id: state.id,
              text: state.name,
              value: state.id,
            });
            this.allstateItems.push({
              id: state.id,
              text: state.name,
              value: state.id,
              country_id: state.country_id,
            });
          });
          //City
          masters.cities.forEach((city) => {
            this.allcityItems.push({
              id: city.id,
              text: city.name,
              value: city.id,
              state_id: city.state_id,
            });
          });
          //Agent Type
          masters.agent_types.forEach((agent_type) => {
            this.agentTypeItems.push({
              id: agent_type.id,
              text: agent_type.description,
              value: agent_type.id,
            });
          });
          //Account Group
          masters.account_groups.forEach((account_group) => {
            this.accountGroupItems.push({
              id: account_group.id,
              text: account_group.name,
              value: account_group.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          window.location.href = "/auth/login";
        });
    },
    onFileChange() {
      this.warningImageLoader = false;
      this.cancel_cheque_file = this.$refs.cancel_cheque_file?.files[0];
      if (this.cancel_cheque_file) {
        let reader = new FileReader();
        reader.readAsDataURL(this.cancel_cheque_file);
        reader.onload = (evt) => {
          let img = new Image();
          img.onload = () => {
            this.imageLoader = false;
            this.cancel_cheque_image_url = URL.createObjectURL(
              this.cancel_cheque_file
            );
            this.imageErrors = "";
          };
          img.src = evt.target.result;
        };

        reader.onerror = (evt) => {
          console.error(evt);
        };
      }
      this.pan_file = this.$refs.pan_file?.files[0];
      if (this.pan_file) {
        let reader = new FileReader();
        reader.readAsDataURL(this.pan_file);
        reader.onload = (evt) => {
          let img = new Image();
          img.onload = () => {
            this.imageLoader = false;
            this.pan_image_url = URL.createObjectURL(this.pan_file);
            this.imageErrors = "";
          };
          img.src = evt.target.result;
        };

        reader.onerror = (evt) => {
          console.error(evt);
        };
      }
      // this.image_url = URL.createObjectURL(this.file);
    },
    async getData() {
      this.isLoading = true;
      let encryptedId = this.$route.params.agentId;
      axios
        .get(`/agents/${encryptedId}`)
        .then((response) => {
          this.form = response.data.data;
          this.old_json = response.data.data;
          this.getCountryWiseState();
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      this.isLoading = false;
    },
    getCountryWiseState(parent) {
      parent.state_id = "";
      parent.stateItems = [];
      parent.stateItems = this.allstateItems.filter((item) => {
        return item.country_id == parent.bank_country_id;
      });
    },
    getStateWiseCity(parent) {
      parent.city_id = "";
      parent.cityItems = [];
      if (parent.state_id) {
        parent.cityItems = this.allcityItems.filter((item) => {
          return item.state_id == parent.state_id;
        });
      } else {
        parent.cityItems = this.allcityItems.filter((item) => {
          return item.state_id == parent.bank_state_id;
        });
      }
    },
    Add_Empty_BankDetail() {
      let agent_bank_detail = {
        account_no: "",
      };
      this.form.agent_bank_details.push(agent_bank_detail);
    },
    deleteBankDetail(agent_bank_detail) {
      this.form.agent_bank_details.splice(
        this.form.agent_bank_details.indexOf(agent_bank_detail),
        1
      );
      this.isDeleteBankDetailDialogs = [];
    },
    async save() {
      try {
        this.isLoading = true;
        let formTest = {};
        Object.assign(formTest, this.form);
        let formData = this.jsonToFormData(formTest);
        let pan_file = this.$refs.pan_file.files[0];
        let cancel_cheque_file = this.$refs.cancel_cheque_file.files[0];
        formData.append("pan_imagepath", pan_file);
        formData.append("cancel_cheque_imagepath", cancel_cheque_file);
        await axios
          .post("/agents", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(async () => {
            await this.saveUserTimestamp(null, this.form);
            this.isLoading = false;
            this.$router.push("/agents");
          })
          .catch((error) => {
            // redirect to Home Page
            window.code.href = "/auth/login";
            console.error("Error fetching data:", error);
          });
      } catch (e) {
        // alert("error");
        console.log(e);
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    jsonToFormData(data) {
      const formData = new FormData();
      this.buildFormData(formData, data);
      return formData;
    },
    buildFormData(formData, data, parentKey) {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;
        formData.append(parentKey, value);
      }
    },
  },
};
</script>
