<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center" class="mt-4 ml-4">
        <v-col>
          <span class="text-h5 font-weight-bold" v-text="'Values'"></span>
          <v-btn
            class="mx-2 mb-2"
            fab
            x-small
            color="primary"
            to="/values/create"
          >
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-progress-linear
        v-if="isLoading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Sr No</th>
                    <th class="text-left">Name</th>
                    <th class="text-right  action_th">Action</th>
                  </tr>
                </thead>
                <tbody v-if="values.length != 0">
                  <tr v-for="(value, i) in values" :key="`value${i}`">
                    <td style="min-width: 85px">
                      &nbsp;
                      {{ i + 1 }}
                    </td>
                    <td>{{ value.name }}</td>
                    <td class="text-right">
                      <v-btn
                        class="ml-2"
                        fab
                        x-small
                        color="primary"
                        :to="`/values/${value.id}`"
                      >
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="6" class="text-center">No data found</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-pagination v-model="page" :length="paginationLength">
            </v-pagination>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";

export default {
  name: "valuesPage",
  data() {
    return {
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      isLoading: false,
      values: [],
    };
  },

  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let values = await axios.get(`/values`);
      this.values = values.data.data;
      this.count = values.data.count;
      this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
      this.isLoading = false;
    },
  },
};
</script>
