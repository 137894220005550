import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        // background: "#FFFFFF",
        background: "#74d742",
        'background-image': `url(${require('@/assets/bg.jpg')})`,
        backgroundImage: `url(${require('@/assets/bg.jpg')})`,
        // primary: "#00b0f1",
        primary: "#116fff",
        // primary: "#e60040",
        // background: colors.blue.lighten5
      },
      options: {
        customProperties: true,
      },
    },
  },
});
