<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn
            class="mr-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Add Vendor
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.name"
                outlined
                dense
                label="Name *"
                prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.landline_no"
                outlined
                dense
                label="Landline No"
                prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.landline_no"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.vendor_preference"
                outlined
                dense
                label="Vendor Preference"
                prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.vendor_preference"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.contact_person_name"
                outlined
                dense
                label="Contact Person Name"
                prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.contact_person_name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.contact_no"
                outlined
                dense
                label="Contact No"
                prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.contact_no"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.email"
                outlined
                dense
                label="Primary Email *"
                prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.contact_person_name_2"
                outlined
                dense
                label="Alternate Contact Person Name"
                prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.contact_person_name_2"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.contact_no_2"
                outlined
                dense
                label="Alternate Contact No"
                prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.contact_no_2"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.email_2"
                outlined
                dense
                label="Secondary Email"
                prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.email_2"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="4">
              <v-autocomplete
                ref="state"
                outlined
                dense
                v-model="form.state_id"
                :items="stateItems"
                label="State *"
                prepend-inner-icon="mdi-earth"
                clearable
                @input="getStateWiseCity(form)"
                :error-messages="errors.state_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="city"
                :disabled="!form.state_id"
                outlined
                dense
                v-model="form.city_id"
                :items="form.cityItems"
                label="City *"
                prepend-inner-icon="mdi-earth"
                clearable
                :error-messages="errors.city_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.pincode"
                outlined
                dense
                label="Pincode *"
                prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.pincode"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.gstn"
                outlined
                dense
                label="GSTN"
                prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.gstn"
              ></v-text-field>
            </v-col>

            <v-col sm="4">
              <v-text-field
                v-model="form.interest_percentage"
                outlined
                dense
                label="Interest Percentage"
                prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.interest_percentage"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.pan_no"
                outlined
                dense
                label="Pan No"
                prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.pan_no"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="account_group_id"
                outlined
                dense
                v-model="form.account_group_id"
                :items="accountGroupItems"
                label="Account Group"
                prepend-inner-icon="mdi-earth"
                clearable
                :error-messages="errors.account_group_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-switch
                class="mt-0 ml-5"
                v-model="form.msme_type"
                inset
                :label="`Is MSME: ${form.msme_type ? 'YES' : 'NO'}`"
              ></v-switch>
            </v-col>
            <v-col sm="4" v-if="form.msme_type">
              <v-text-field
                v-model="form.msme_no"
                outlined
                dense
                label="MSME No *"
                prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.msme_no"
              ></v-text-field>
            </v-col>
            <v-col sm="12">
              <v-textarea
                outlined
                rows="2"
                v-model="form.address"
                label="Address"
              ></v-textarea>
            </v-col>
            <v-col sm="4">
              <v-switch
                class="mt-0 ml-5"
                v-model="form.is_tds_applicable"
                inset
                :label="`Is TDS Applicable: ${
                  form.is_tds_applicable ? 'YES' : 'NO'
                }`"
              ></v-switch>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="purchase_type_id"
                outlined
                dense
                v-model="form.purchase_type_id"
                :items="purchaseTypeItems"
                label="Purchase Type *"
                prepend-inner-icon="mdi-earth"
                clearable
                :error-messages="errors.purchase_type_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="status"
                outlined
                dense
                v-model="form.status"
                :items="approvalStatuses"
                label="Approval Status *"
                prepend-inner-icon="mdi-earth"
                :error-messages="errors.status"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-switch
                class="mt-0 ml-5"
                v-model="form.is_active"
                inset
                :label="`Is Active: ${form.is_active ? 'YES' : 'NO'}`"
              ></v-switch>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <section>
            <template v-if="form.vendor_certificates.length">
              <v-divider class="my-2"> </v-divider>
              <h3 class="my-4">List of Certificate(s) for this Vendor:</h3>
              <v-row
                v-for="(certificate, vC) in form.vendor_certificates"
                :key="`certificate${vC}`"
              >
                <v-col sm="4">
                  <v-autocomplete
                    ref="certificate"
                    outlined
                    dense
                    v-model="certificate.certificate_id"
                    :items="certificateTypeItems"
                    label="Certificate"
                    prepend-inner-icon="mdi-earth"
                    clearable
                    :error-messages="errors.certificate_id"
                  ></v-autocomplete>
                </v-col>

                <v-col
                  sm="4"
                  v-if="
                    certificateTypeItems.find(
                      (ct) => certificate.certificate_id == ct.id
                    )?.text === 'OTHER'
                  "
                >
                  <v-text-field
                    v-model="certificate.name"
                    outlined
                    dense
                    label="Certificate Name"
                    prepend-inner-icon="mdi-bank"
                    :error-messages="
                      errors[`vendor_certificates.${vC}.name`]
                        ? errors[`vendor_certificates.${vC}.name`]
                        : ``
                    "
                  ></v-text-field>
                </v-col>
                <v-col sm="4">
                  <v-text-field
                    v-model="certificate.number"
                    outlined
                    dense
                    label="Certificate Number"
                    prepend-inner-icon="mdi-bank"
                    :error-messages="
                      errors[`vendor_certificates.${vC}.number`]
                        ? errors[`vendor_certificates.${vC}.number`]
                        : ``
                    "
                  ></v-text-field>
                </v-col>
                <v-col sm="3">
                  <label for="Certificate File">Upload Certificate</label>
                  <br />
                  <input
                    type="file"
                    id="certificate_file"
                    name="certificate_file"
                    :ref="`certificate_file_${vC}`"
                    accept="image/*"
                    @change="
                      attachmentAdded(certificate, `certificate_file_${vC}`),
                        onFileChange(`certificate_file_${vC}`, vC)
                    "
                  />
                  <v-btn
                    x-small
                    class="reset_image my-1"
                    color="red"
                    dark
                    @click="
                      ($refs[`certificate_file_${vC}`][0].value = null),
                        (certificate.imagepreview = null)
                    "
                    >Remove</v-btn
                  >
                  <div
                    id="preview"
                    v-if="certificate.imagepreview"
                    class="mt-5"
                  >
                    <label for="">Image Preview</label>
                    <br />
                    <img
                      :src="certificate.imagepreview"
                      style="height: 30%; width: 30% !important"
                    />
                  </div>
                </v-col>
                <v-col sm="1">
                  <v-dialog
                    v-model="isDeleteCertificateDialogs[vC]"
                    max-width="600px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        class="ml-9"
                        small
                        dark
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> mdi-close</v-icon>
                      </v-btn>
                    </template>
                    <v-card elevation="10">
                      <v-card-title>
                        <v-icon large color="primary">
                          mdi-information-outline
                        </v-icon>
                        &nbsp; Do you want to remove the section?
                      </v-card-title>
                      <v-card-text>
                        <v-btn
                          color="primary"
                          @click.stop="deleteCertificate(certificate)"
                        >
                          Yes
                        </v-btn>
                        &nbsp;
                        <v-btn
                          color="red"
                          dark
                          @click.stop="isDeleteCertificateDialogs = []"
                        >
                          No
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </template>
            <v-row>
              <v-col sm="12" class="mb-5">
                <v-btn
                  small
                  rounded
                  color="primary"
                  @click="Add_Empty_Certificate"
                >
                  Add Certificate
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="mt-5 mb-5"> </v-divider>
          </section>
          <!-- VENDOR BANK DETAILS -->
          <section>
            <template v-if="form.vendor_bank_details.length">
              <h3 class="my-4">List of Bank(s) for this Vendor:</h3>
              <v-row
                v-for="(bank_detail, vBD) in form.vendor_bank_details"
                :key="`bank_detail${vBD}`"
              >
                <v-col sm="4">
                  <v-autocomplete
                    ref="bank_name"
                    outlined
                    dense
                    v-model="bank_detail.bank_name_id"
                    :items="bankNameItems"
                    label="Bank Names"
                    prepend-inner-icon="mdi-earth"
                    clearable
                    :error-messages="errors.bank_name_id"
                  ></v-autocomplete>
                </v-col>
                <v-col sm="4">
                  <v-text-field
                    v-model="bank_detail.account_no"
                    outlined
                    dense
                    label="Account No"
                    prepend-inner-icon="mdi-bank"
                    :error-messages="
                      errors[`vendor_bank_details.${vBD}.account_no`]
                        ? errors[`vendor_bank_details.${vBD}.account_no`]
                        : ``
                    "
                  ></v-text-field>
                </v-col>
                <v-col sm="4">
                  <v-autocomplete
                    ref="country"
                    outlined
                    dense
                    v-model="bank_detail.bank_country_id"
                    :items="countryItems"
                    label="Country"
                    prepend-inner-icon="mdi-earth"
                    clearable
                    @input="getCountryWiseState(bank_detail)"
                    :error-messages="errors.bank_country_id"
                  ></v-autocomplete>
                </v-col>
                <v-col sm="4">
                  <v-autocomplete
                    ref="state"
                    :disabled="!bank_detail.bank_country_id"
                    outlined
                    dense
                    v-model="bank_detail.bank_state_id"
                    :items="bank_detail.stateItems"
                    label="State"
                    prepend-inner-icon="mdi-earth"
                    clearable
                    @input="getStateWiseCity(bank_detail)"
                    :error-messages="errors.bank_state_id"
                  ></v-autocomplete>
                </v-col>
                <v-col sm="4">
                  <v-autocomplete
                    ref="city"
                    :disabled="!bank_detail.bank_state_id"
                    outlined
                    dense
                    v-model="bank_detail.bank_city_id"
                    :items="bank_detail.cityItems"
                    label="City"
                    prepend-inner-icon="mdi-earth"
                    clearable
                    :error-messages="errors.bank_city_id"
                  ></v-autocomplete>
                </v-col>
                <v-col sm="4">
                  <v-text-field
                    v-model="bank_detail.pincode"
                    outlined
                    dense
                    label="Pincode"
                    prepend-inner-icon="mdi-bank"
                    :error-messages="
                      errors[`vendor_bank_details.${vBD}.pincode`]
                        ? errors[`vendor_bank_details.${vBD}.pincode`]
                        : ``
                    "
                  ></v-text-field>
                </v-col>
                <v-col sm="8">
                  <v-textarea
                    outlined
                    rows="1"
                    dense
                    v-model="bank_detail.address"
                    label="Address"
                  ></v-textarea>
                </v-col>
                <v-col sm="4">
                  <v-text-field
                    v-model="bank_detail.phone_no"
                    outlined
                    dense
                    label="Phone No"
                    prepend-inner-icon="mdi-bank"
                    :error-messages="
                      errors[`vendor_bank_details.${vBD}.phone_no`]
                        ? errors[`vendor_bank_details.${vBD}.phone_no`]
                        : ``
                    "
                  ></v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-text-field
                    v-model="bank_detail.ifsc_code"
                    outlined
                    dense
                    label="IFSC Code"
                    prepend-inner-icon="mdi-bank"
                    :error-messages="
                      errors[`vendor_bank_details.${vBD}.ifsc_code`]
                        ? errors[`vendor_bank_details.${vBD}.ifsc_code`]
                        : ``
                    "
                  ></v-text-field>
                </v-col>
                <v-col sm="1">
                  <v-dialog
                    v-model="isDeleteBankDetailDialogs[p]"
                    max-width="600px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        class="ml-9"
                        small
                        dark
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> mdi-close</v-icon>
                      </v-btn>
                    </template>
                    <v-card elevation="10">
                      <v-card-title>
                        <v-icon large color="primary">
                          mdi-information-outline
                        </v-icon>
                        &nbsp; Do you want to remove the section?
                      </v-card-title>
                      <v-card-text>
                        <v-btn
                          color="primary"
                          @click.stop="deleteBankDetail(bank_detail)"
                        >
                          Yes
                        </v-btn>
                        &nbsp;
                        <v-btn
                          color="red"
                          dark
                          @click.stop="isDeleteBankDetailDialogs = []"
                        >
                          No
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </template>
            <v-row>
              <v-col sm="12" class="mb-5">
                <v-btn
                  small
                  rounded
                  color="primary"
                  @click="Add_Empty_BankDetail"
                >
                  Add Bank Detail
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="mt-5 mb-5"> </v-divider>
          </section>
          <v-btn
            rounded
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="save"
          >
            Save Vendor
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "VendorsCreatePage",
  data() {
    return {
      form: {
        name: "",
        is_active: 1,
        vendor_bank_details: [],
        vendor_certificates: [],
      },
      approvalStatuses: [
        { id: "PENDING", text: "PENDING", value: 0 },
        { id: "APPROVED", text: "APPROVED", value: 1 },
        { id: "REJECTED", text: "REJECTED", value: 2 },
      ],
      bankNameItems: [],
      countryItems: [],
      certificateTypeItems: [],
      stateItems: [],
      allstateItems: [],
      allcityItems: [],
      accountGroupItems: [],
      isDeleteBankDetailDialogs: [],
      isDeleteCertificateDialogs: [],
      purchaseTypeItems: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    attachmentAdded(parent, Key) {
      console.log(parent, Key);

      parent.imagepath = this.$refs[Key][0].files[0];
    },
    async getMasters() {
      await axios
        .get(`vendors/masters`)
        .then((masters) => {
          masters = masters.data;
          //Bank Names
          masters.bank_names.forEach((bN) => {
            this.bankNameItems.push({
              id: bN.id,
              text: bN.description,
              value: bN.id,
            });
          });
          //Countries
          masters.countries.forEach((country) => {
            this.countryItems.push({
              id: country.id,
              text: country.description,
              value: country.id,
            });
          });
          //Certificate Type
          masters.certificate_types.forEach((certificate_type) => {
            this.certificateTypeItems.push({
              id: certificate_type.id,
              text: certificate_type.description,
              value: certificate_type.id,
            });
          });
          //State
          masters.states.forEach((state) => {
            this.stateItems.push({
              id: state.id,
              text: state.name,
              value: state.id,
            });
            this.allstateItems.push({
              id: state.id,
              text: state.name,
              value: state.id,
              country_id: state.country_id,
            });
          });
          //City
          masters.cities.forEach((city) => {
            this.allcityItems.push({
              id: city.id,
              text: city.name,
              value: city.id,
              state_id: city.state_id,
            });
          });
          //Account Groups
          masters.account_groups.forEach((aG) => {
            this.accountGroupItems.push({
              id: aG.id,
              text: aG.name,
              value: aG.id,
            });
          });
          //Purchase Types
          masters.purchase_types.forEach((pT) => {
            console.log(pT);
            this.purchaseTypeItems.push({
              id: pT.id,
              text: pT.description,
              value: pT.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          // window.location.href = "/auth/login";
        });
    },
    getCountryWiseState(parent) {
      parent.state_id = "";
      parent.stateItems = [];
      parent.stateItems = this.allstateItems.filter((item) => {
        return item.country_id == parent.bank_country_id;
      });
    },
    getStateWiseCity(parent) {
      parent.city_id = "";
      parent.cityItems = [];
      if (parent.state_id) {
        parent.cityItems = this.allcityItems.filter((item) => {
          return item.state_id == parent.state_id;
        });
      } else {
        parent.cityItems = this.allcityItems.filter((item) => {
          return item.state_id == parent.bank_state_id;
        });
      }
    },
    Add_Empty_BankDetail() {
      let vendor_bank_detail = {
        account_no: "",
      };
      this.form.vendor_bank_details.push(vendor_bank_detail);
    },
    deleteBankDetail(vendor_bank_detail) {
      this.form.vendor_bank_details.splice(
        this.form.vendor_bank_details.indexOf(vendor_bank_detail),
        1
      );
      this.isDeleteBankDetailDialogs = [];
    },
    Add_Empty_Certificate() {
      let vendor_certificate = {
        number: "",
        imagepath: "",
        certificate_id: "",
        imagepreview: "",
      };
      this.form.vendor_certificates.push(vendor_certificate);
    },
    deleteCertificate(vendor_certidficate) {
      this.form.vendor_certidficates.splice(
        this.form.vendor_certidficates.indexOf(vendor_certidficate),
        1
      );
      this.isDeleteCertificateDialogs = [];
    },
    async save() {
      try {
        this.isLoading = true;
        let formTest = {};
        Object.assign(formTest, this.form);
        let formData = this.jsonToFormData(formTest);
        await axios
          .post("/vendors", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(async () => {
            await this.saveUserTimestamp(null, this.form);
            this.isLoading = false;
            this.$router.push("/vendors");
          })
          .catch((error) => {
            // redirect to Home Page
            if (error.response.status != 422) {
              // window.location.href = "/auth/login";
            }
            console.error("Error fetching data:", error);
          });
      } catch (e) {
        // alert("error");
        console.log(e);
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    jsonToFormData(data) {
      const formData = new FormData();
      this.buildFormData(formData, data);
      return formData;
    },
    buildFormData(formData, data, parentKey) {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;
        formData.append(parentKey, value);
      }
    },
    onFileChange(name, index) {
      this.warningImageLoader = false;
      console.log(name, index);

      this.form.vendor_certificates[index].image_file =
        this.$refs[name][0].files[0];
      if (this.form.vendor_certificates[index].image_file) {
        console.log(this.form.vendor_certificates[index]);

        let reader = new FileReader();
        reader.readAsDataURL(this.form.vendor_certificates[index].image_file);
        reader.onload = (evt) => {
          let img = new Image();
          img.onload = () => {
            this.imageLoader = false;
            this.form.vendor_certificates[index].imagepreview =
              URL.createObjectURL(
                this.form.vendor_certificates[index].image_file
              );
            this.imageErrors = "";
          };
          img.src = evt.target.result;
        };

        reader.onerror = (evt) => {
          console.error(evt);
        };
      }
    },
  },
};
</script>
