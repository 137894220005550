<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn
            class="mr-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Add Vendor Group
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete
                ref="group_type_id"
                outlined
                v-model="form.group_type_id"
                :items="groupTypeItems"
                label="Group Type *"
                prepend-inner-icon="mdi-earth"
                clearable
                :error-messages="errors.group_type_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.name"
                outlined
                label="Name *"
                prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.code"
                outlined
                label="Code *"
                prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.code"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-switch
                class="mt-0 ml-5"
                v-model="form.is_interval_code"
                inset
                :label="`Is Internal Code: ${
                  form.is_interval_code ? 'YES' : 'NO'
                }`"
              ></v-switch>
            </v-col>
            <v-col sm="4">
              <v-switch
                class="mt-0 ml-5"
                v-model="form.is_active"
                inset
                :label="`Is Active: ${form.is_active ? 'YES' : 'NO'}`"
              ></v-switch>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <v-btn
            rounded
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="save"
          >
            Save Vendor Group
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "VendorGroupsCreatePage",
  data() {
    return {
      form: {
        name: "",
        is_active: 1,
      },
      groupTypeItems: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      await axios
        .get(`vendor_groups/masters`)
        .then((masters) => {
          masters = masters.data;
          //Vendor Types
          masters.group_types.forEach((aT) => {
            this.groupTypeItems.push({
              id: aT.id,
              text: aT.description,
              value: aT.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          // window.location.href = "/auth/login";
        });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios
          .post("/vendor_groups", this.form)
          .then(async () => {
            await this.saveUserTimestamp(null, this.form);
            this.isLoading = false;
            this.$router.push("/vendor-groups");
          })
          .catch((error) => {
            // redirect to Home Page
            if (error.response.status != 422) {
              window.location.href = "/auth/login";
            }
            this.isLoading = false;
            console.error("Error fetching data:", error);
          });
      } catch (e) {
        // alert("error");
        console.log(e);
        this.isLoading = false;
      }
  this.isLoading = false;
      this.isLoading = false;
    },
  },
};
</script>
