<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-title>
              <h3 class="font-weight-bold" v-text="'Roles'"></h3>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field
                    solo
                    rounded
                    v-model="filter.search_keyword"
                    label="Search ... "
                    color="#e60040"
                    prepend-inner-icon="mdi-magnify"
                    clearable
                    @click:clear="
                      (filter.search_keyword = null), (page = 1), getData()
                    "
                    @keydown.enter="(page = 1), getData()"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-text>
              <section v-if="isLoading" style="height: 285px">
                <v-progress-linear
                  indeterminate
                  color="primary"
                ></v-progress-linear>
              </section>
              <v-simple-table
                v-else
                fixed-header
                height="285"
                class="striped-table"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left sr_no">Sr No</th>
                      <th class="text-left">Status</th>
                      <th class="text-left" style="min-width: none">Role</th>
                      <th class="text-right action_th">Action</th>
                    </tr>
                  </thead>
                  <tbody v-if="roles.length != 0">
                    <tr
                      v-for="(role, i) in roles"
                      :key="`role_${i}`"
                      :class="{
                        'table-row-even': i % 2 === 0,
                        'table-row-odd': i % 2 !== 0,
                        'table-row-last': roles.length - 1 == i,
                      }"
                    >
                      <td style="min-width: 85px">
                        {{ i + 1 }}
                      </td>
                      <td>
                        <v-tooltip bottom v-if="role.is_active == 1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="teal"
                              text-color="white"
                            >
                              <v-avatar left>
                                <v-icon size="15"
                                  >mdi-checkbox-marked-circle</v-icon
                                >
                              </v-avatar>
                              Active
                            </v-chip>
                          </template>
                          <span>Role is Active</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="role.is_active == 0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="red"
                              text-color="white"
                            >
                              <v-avatar left>
                                <v-icon size="15">mdi-close-circle</v-icon>
                              </v-avatar>
                              Inactive
                            </v-chip>
                          </template>
                          <span>Role is InActive</span>
                        </v-tooltip>
                      </td>
                      <td>
                        {{ role.name || "" }}
                      </td>
                      <td class="text-right">
                        <!-- <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              fab
                              x-small
                              class="my-auto"
                              color="error"
                              @click="Delete_this(role.id)"
                            >
                              <v-icon dark> mdi-delete </v-icon>
                            </v-btn>
                          </template>
                          <span>Delete this Role</span>
                        </v-tooltip> -->
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              class="ml-2"
                              fab
                              x-small
                              color="primary"
                              :to="`/roles/${encryptIt(role.id)}`"
                            >
                              <v-icon dark> mdi-pencil </v-icon>
                            </v-btn>
                          </template>
                          <span>Edit this Role</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="7" class="text-center">No data found</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-pagination rounded v-model="page" :length="paginationLength">
              </v-pagination>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Clear Success Dialog Box -->
      <v-dialog v-model="is_deleted_role" width="600">
        <v-card rounded="xxl">
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: green; font-size: 90px; width: 200px !important"
                >
                  mdi-delete-empty
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>Role deleted successfully.</h3>
                      <br />
                      <v-btn
                        color="success"
                        @click="is_deleted_role = false"
                      >
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "rolesPage",
  data() {
    return {
      filter: {
        search_keyword: "",
        is_active: "",
      },
      roles: [],
      statuses: [
        {
          id: 0,
          text: "Select Active Status",
          value: "",
        },
        { id: "ACTIVE", text: "ACTIVE", value: "1" },
        { id: "INACTIVE", text: "INACTIVE", value: "0" },
      ],
      ExportRoleTypes: [],
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      excel_fields: {
        "Sr No": "sr_no",
        Name: "name",
        Price: "price",
      },
      isLoading: false,
      isExcelloading: false,
      delete_role: false,
      is_deleted_role: false,
    };
  },
  watch: {
    page: "getData",
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      this.filter.search_keyword = this.filter.search_keyword
        ? this.filter.search_keyword
        : "";
      this.filter.is_active = this.filter.is_active ? this.filter.is_active : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&is_active=" +
        this.filter.is_active +
        "&search_keyword=" +
        this.filter.search_keyword;
      await axios
        .get(`roles?${query}`)
        .then((response) => {
          this.roles = response.data.data;
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
    //Generate Excel
    async generateExcel() {
      let query = "search_keyword=" + this.filter.search_keyword;
      let response = await axios.get(`role_exports?${query}`);
      console.log(response.data.data);
      if (response) {
        window.open(`${response.data.data}`, "_blank");
      }
    },
    async Delete_this(id) {
      this.delete_role = true;
      await axios
        .post(`/roles/delete/${this.encryptIt(id)}`)
        .then(() => {
          this.delete_role = false;
          this.is_deleted_role = true;
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
  },
};
</script>
