<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-title>
              <h3 class="font-weight-bold" v-text="'Vendors'"></h3>
              <v-btn
                class="mx-2 mb-2"
                fab
                x-small
                color="primary"
                to="/vendors/create"
              >
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field
                    solo
                    rounded
                    v-model="filter.search_keyword"
                    label="Search ... "
                    color="#e60040"
                    prepend-inner-icon="mdi-magnify"
                    clearable
                    @click:clear="
                      (filter.search_keyword = null), (page = 1), getData()
                    "
                    @keydown.enter="(page = 1), getData()"
                  >
                  </v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete
                    ref="Vendor Group"
                    solo
                    rounded
                    v-model="filter.vendor_group_id"
                    :items="vendorGroupItems"
                    clearable
                    @click:clear="(filter.vendor_group_id = null), (page = 1)"
                    label="Vendor Group"
                    @input="getData"
                    prepend-inner-icon="mdi-earth"
                  ></v-autocomplete>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete
                    ref="Account Group"
                    solo
                    rounded
                    v-model="filter.account_group_id"
                    :items="accountGroupItems"
                    clearable
                    @click:clear="(filter.account_group_id = null), (page = 1)"
                    label="Account Group"
                    @input="getData"
                    prepend-inner-icon="mdi-earth"
                  ></v-autocomplete>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete
                    ref="Purchase Type"
                    solo
                    rounded
                    v-model="filter.purchase_type_id"
                    :items="purchaseTypeItems"
                    clearable
                    @click:clear="(filter.purchase_type_id = null), (page = 1)"
                    label="Purchase Type"
                    @input="getData"
                    prepend-inner-icon="mdi-earth"
                  ></v-autocomplete>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete
                    ref="MSME Type"
                    solo
                    rounded
                    v-model="filter.msme_type"
                    :items="msmeTypeItems"
                    clearable
                    @click:clear="(filter.msme_type = null), (page = 1)"
                    label="MSME Type"
                    @input="getData"
                    prepend-inner-icon="mdi-toggle-switch"
                  ></v-autocomplete>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete
                    ref="status"
                    solo
                    rounded
                    v-model="filter.is_active"
                    :items="statuses"
                    clearable
                    @click:clear="(filter.is_active = null), (page = 1)"
                    label="Status"
                    @input="getData"
                    prepend-inner-icon="mdi-toggle-switch"
                  ></v-autocomplete>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete
                    ref="Approval_status"
                    solo
                    rounded
                    v-model="filter.status"
                    :items="approvalStatuses"
                    clearable
                    @click:clear="(filter.status = null), (page = 1)"
                    label="Approval Status"
                    @input="getData"
                    prepend-inner-icon="mdi-toggle-switch"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-text>
              <section v-if="isLoading" style="height: 285px">
                <v-progress-linear
                  indeterminate
                  color="primary"
                ></v-progress-linear>
              </section>
              <v-simple-table
                v-else
                fixed-header
                height="285"
                class="striped-table"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left sr_no">Sr No</th>
                      <th class="text-left">Status</th>
                      <th class="text-left">Approval Status</th>
                      <th class="text-left" style="min-width: none">Name</th>
                      <th class="text-left" style="min-width: none">Address</th>
                      <th class="text-left" style="min-width: none">City</th>
                      <th class="text-left" style="min-width: none">Pincode</th>
                      <th class="text-left" style="min-width: none">State</th>
                      <th class="text-left" style="min-width: none">GSTN</th>
                      <th class="text-right action_th">Action</th>
                    </tr>
                  </thead>
                  <tbody v-if="vendors.length != 0">
                    <tr
                      v-for="(vendor, i) in vendors"
                      :key="`vendor_${i}`"
                      :class="{
                        'table-row-even': i % 2 === 0,
                        'table-row-odd': i % 2 !== 0,
                        'table-row-last': vendors.length - 1 == i,
                      }"
                    >
                      <td style="min-width: 85px">
                        {{ i + 1 }}
                      </td>
                      <td>
                        <v-tooltip bottom v-if="vendor.is_active == 1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="teal"
                              text-color="white"
                            >
                              <v-avatar left>
                                <v-icon size="15"
                                  >mdi-checkbox-marked-circle</v-icon
                                >
                              </v-avatar>
                              Active
                            </v-chip>
                          </template>
                          <span>Vendors is Active</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="vendor.is_active == 0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="red"
                              text-color="white"
                            >
                              <v-avatar left>
                                <v-icon size="15">mdi-close-circle</v-icon>
                              </v-avatar>
                              Inactive
                            </v-chip>
                          </template>
                          <span>Vendors is InActive</span>
                        </v-tooltip>
                      </td>
                      <td>
                        <v-tooltip bottom v-if="vendor.status == 0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="yellow"
                              text-color="black"
                            >
                              <v-avatar left>
                                <v-icon size="15">mdi-close-circle</v-icon>
                              </v-avatar>
                              Pending
                            </v-chip>
                          </template>
                          <span>Pending for Approval</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="vendor.status == 1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="teal"
                              text-color="white"
                            >
                              <v-avatar left>
                                <v-icon size="15">mdi-check-decagram</v-icon>
                              </v-avatar>
                              Approved
                            </v-chip>
                          </template>
                          <span>Vendors is Approved</span>
                        </v-tooltip>

                        <v-tooltip bottom v-if="vendor.status == 2">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="red"
                              text-color="white"
                            >
                              <v-avatar left>
                                <v-icon size="15">mdi-close-circle</v-icon>
                              </v-avatar>
                              Rejected
                            </v-chip>
                          </template>
                          <span>Vendors is Rejected</span>
                        </v-tooltip>
                      </td>
                      <td>{{ vendor.name || "" }}</td>
                      <td>{{ vendor.address || "" }}</td>
                      <td>{{ vendor.city.name || "" }}</td>
                      <td>{{ vendor.pincode || "" }}</td>
                      <td>{{ vendor.state.name || "" }}</td>
                      <td>{{ vendor.gstn || "" }}</td>
                      <td class="text-right">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              fab
                              x-small
                              class="my-auto"
                              color="error"
                              @click="Delete_this(vendor.id)"
                            >
                              <v-icon dark> mdi-delete </v-icon>
                            </v-btn>
                          </template>
                          <span>Delete this Vendors</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              class="ml-2"
                              fab
                              x-small
                              color="primary"
                              :to="`/vendors/${encryptIt(vendor.id)}`"
                            >
                              <v-icon dark> mdi-pencil </v-icon>
                            </v-btn>
                          </template>
                          <span>Edit this Vendors</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="10" class="text-center">No data found</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-pagination rounded v-model="page" :length="paginationLength">
              </v-pagination>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Clear Success Dialog Box -->
      <v-dialog v-model="is_deleted_vendor" width="600">
        <v-card rounded="xxl">
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: green; font-size: 90px; width: 200px !important"
                >
                  mdi-delete-empty
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>Vendors deleted successfully.</h3>
                      <br />
                      <v-btn color="success" @click="is_deleted_vendor = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "VendorsPage",
  data() {
    return {
      filter: {
        search_keyword: "",
        is_active: "",
        status: "",
      },
      vendors: [],
      prefixItems: [],
      stateItems: [],
      cityItems: [],
      allcityItems: [],
      gstRegTypeItems: [],
      vendorGroupItems: [],
      accountGroupItems: [],
      msmeTypeItems: [
        { id: "MSME", text: "MSME", value: 1 },
        { id: "Non-MSME", text: "Non-MSME", value: 2 },
      ],
      purchaseTypeItems: [],
      statuses: [
        {
          id: 0,
          text: "Select Active Status",
          value: "",
        },
        { id: "ACTIVE", text: "ACTIVE", value: "1" },
        { id: "INACTIVE", text: "INACTIVE", value: "0" },
      ],
      approvalStatuses: [
        { id: "PENDING", text: "PENDING", value: 0 },
        { id: "APPROVED", text: "APPROVED", value: 1 },
        { id: "REJECTED", text: "REJECTED", value: 2 },
      ],
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      isLoading: false,
      delete_vendor: false,
      is_deleted_vendor: false,
    };
  },
  watch: {
    page: "getData",
  },
  mounted() {
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      await axios
        .get(`vendors/masters`)
        .then((masters) => {
          masters = masters.data;
          //Prefixs
          masters.prefixs.forEach((p) => {
            this.prefixItems.push({
              id: p.id,
              text: p.description,
              value: p.id,
            });
          });
          //State
          masters.states.forEach((state) => {
            this.stateItems.push({
              id: state.id,
              text: state.name,
              value: state.id,
            });
          });
          //City
          masters.cities.forEach((city) => {
            this.cityItems.push({
              id: city.id,
              text: city.name,
              value: city.id,
            });
            this.allcityItems.push({
              id: city.id,
              text: city.name,
              value: city.id,
              state_id: city.state_id,
            });
          });
          //GST Reg Types
          masters.gst_reg_types.forEach((grt) => {
            this.gstRegTypeItems.push({
              id: grt.id,
              text: grt.description,
              value: grt.id,
            });
          });
          //Vendor Groups
          masters.vendor_groups.forEach((vG) => {
            this.vendorGroupItems.push({
              id: vG.id,
              text: vG.name,
              value: vG.id,
            });
          });
          //Account Groups
          masters.account_groups.forEach((aG) => {
            this.accountGroupItems.push({
              id: aG.id,
              text: aG.name,
              value: aG.id,
            });
          });
          //Purchase Types
          masters.purchase_types.forEach((pT) => {
            console.log(pT);
            this.purchaseTypeItems.push({
              id: pT.id,
              text: pT.description,
              value: pT.id,
            });
          });
          console.log(this.purchaseTypeItems);
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          // window.location.href = "/auth/login";
        });
    },
    async getData() {
      this.isLoading = true;
      this.filter.search_keyword = this.filter.search_keyword
        ? this.filter.search_keyword
        : "";
      this.filter.is_active = this.filter.is_active
        ? this.filter.is_active
        : "";
      this.filter.vendor_group_id = this.filter.vendor_group_id
        ? this.filter.vendor_group_id
        : "";
      this.filter.account_group_id = this.filter.account_group_id
        ? this.filter.account_group_id
        : "";
      this.filter.purchase_type_id = this.filter.purchase_type_id
        ? this.filter.purchase_type_id
        : "";
      this.filter.msme_type = this.filter.msme_type
        ? this.filter.msme_type
        : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&is_active=" +
        this.filter.is_active +
        "&status=" +
        this.filter.status +
        "&vendor_group_id=" +
        this.filter.vendor_group_id +
        "&account_group_id=" +
        this.filter.account_group_id +
        "&purchase_type_id=" +
        this.filter.purchase_type_id +
        "&msme_type=" +
        this.filter.msme_type +
        "&search_keyword=" +
        this.filter.search_keyword;
      await axios
        .get(`vendors?${query}`)
        .then((response) => {
          this.vendors = response.data.data;
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
    async Delete_this(id) {
      this.delete_vendor = true;
      await axios
        .post(`/vendors/delete/${this.encryptIt(id)}`)
        .then(() => {
          this.delete_vendor = false;
          this.is_deleted_vendor = true;
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
  },
};
</script>
