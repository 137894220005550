import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";

import router from "./router";
import store from "./store";

require("@/store/subscriber");

require("./mixin.js");
require("./axios.js");
require("./filters.js");

Vue.config.productionTip = false;

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

import { VueEditor } from "vue2-editor";
Vue.component("VueEditor", VueEditor);

import CryptoJS from "crypto-js";
window.CryptoJS = CryptoJS;
// Vue.component("CryptoJS", CryptoJS);

store.dispatch("auth/attempt", localStorage.getItem("token")).then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});
