<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn
            class="mr-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Edit City
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete
                ref="country"
                outlined
                v-model="form.country_id"
                :items="countryItems"
                label="Country"
                prepend-inner-icon="mdi-earth"
                clearable
                @input="getCountryWiseState()"
                :error-messages="errors.country_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="state"
                :disabled="!form.country_id"
                outlined
                v-model="form.state_id"
                :items="stateItems"
                label="State"
                prepend-inner-icon="mdi-earth"
                clearable
                :error-messages="errors.state_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.name"
                outlined
                label="Name"
                prepend-inner-icon="mdi-card-text-outline"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <v-btn
            rounded
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="save"
          >
            Update City
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";

export default {
  name: "CityEditPage",
  data() {
    return {
      form: {
        is_active: false,
      },
      countryItems: [],
      stateItems: [],
      allstateItems: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getMasters();
    this.getData();
  },
  methods: {
    async getMasters() {
      await axios
        .get(`cities/masters`)
        .then((masters) => {
          masters = masters.data;
          //Countries
          masters.countries.forEach((country) => {
            this.countryItems.push({
              id: country.id,
              text: country.description,
              value: country.id,
            });
          });
          //State
          masters.states.forEach((state) => {
            this.stateItems.push({
              id: state.id,
              text: state.name,
              value: state.id,
            });
            this.allstateItems.push({
              id: state.id,
              text: state.name,
              value: state.id,
              country_id: state.country_id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          window.location.href = "/auth/login";
        });
    },
    
    async getData() {
      this.isLoading = true;
      let encryptedId = this.$route.params.cityId;
      axios
        .get(`/cities/${encryptedId}`)
        .then((response) => {
          this.form = response.data.data;
          this.old_json = response.data.data;
          this.getCountryWiseState()
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      this.isLoading = false;
    },
    getCountryWiseState() {
      this.stateItems = [];
      this.stateItems = this.allstateItems.filter((item) => {
        return item.country_id == this.form.country_id;
      });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios
          .patch(`/cities/${this.$route.params.cityId}`, this.form)
          .then(async () => {
            await this.saveUserTimestamp(null, this.form);
            this.isLoading = false;
            this.$router.push("/cities");
          })
          .catch((error) => {
            // redirect to Home Page
            window.code.href = "/auth/login";
            console.error("Error fetching data:", error);
          });
      } catch (e) {
        // alert("error");
        console.log(e);
        this.isLoading = false;
      }
  this.isLoading = false;
    },
    
  },
};
</script>
