<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-btn
          text
          :href="`${mediaUrl}`"
          rounded
          color="primary"
          target="_blank"
        >
          <v-icon>mdi-download</v-icon>
          Download sample file
        </v-btn>
        <v-card-title> Upload Pjps Data </v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-if="crud_pjps.length == 0">
              <input
                type="file"
                id="file"
                name="file"
                ref="file"
                accept="application/ms-excel"
                multiple
                @input="handleFileUpload"
              />
              <section v-if="isUploading">
                <v-progress-linear
                  class="mt-5"
                  indeterminate
                  color="primary"
                ></v-progress-linear>
              </section>
            </v-col>
            <v-col v-else>
              <v-btn
                color="success"
                :loading="isProcessing"
                :disabled="isProcessing"
                @click="processFile"
              >
                Upload file</v-btn
              >

              &nbsp;
              <v-btn color="error" @click="truncatePendingDocuments">
                Delete</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <span style="color: red" v-show="isError">{{ error_msg }} </span>
          </v-row>
        </v-card-text>
      </v-card>
      <br />
      <v-card elevation="6" rounded="xxl">
        <v-card-text>
          <v-simple-table fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Name</th>
                  <th class="text-left">Location</th>
                  <th class="text-left">Date</th>
                  <th class="text-left">Description</th>
                  <th class="text-left">User Name</th>
                  <th class="text-left">Outlet Name</th>
                  <th class="text-left">Is Outlet Visited</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(crudpjp, at) in crud_pjps" :key="`crudpjp${at}`">
                  <td>{{ crudpjp.name }}</td>
                  <td>{{ crudpjp.location }}</td>
                  <td>{{ crudpjp.date }}</td>
                  <td>{{ crudpjp.description }}</td>
                  <td>{{ crudpjp.user_name }}</td>
                  <td>{{ crudpjp.outlet_name }}</td>
                  <td>{{ crudpjp.is_outlet_visited }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
      <!-- Upload Success Dialog Box -->
      <v-dialog v-model="is_Uploaded" width="600">
        <v-card rounded="xxl">
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: green; font-size: 90px; width: 200px !important"
                >
                  mdi-cloud-upload-outline
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>
                        The file has been uploaded successfully. Now you can
                        Preview the List & process accordingly.
                      </h3>
                      <br />
                      <v-btn color="success" @click="is_Uploaded = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Process Success Dialog Box -->
      <v-dialog v-model="is_Processed" width="600">
        <v-card rounded="xxl">
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: green; font-size: 90px; width: 200px !important"
                >
                  mdi-progress-download
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>
                        The file has been processed successfully. Now you can
                        see your pjps in Pjp's Tab.
                      </h3>
                      <br />
                      <v-btn color="success" @click="is_Processed = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Truncate Success Dialog Box -->
      <v-dialog v-model="is_Truncated" width="600">
        <v-card rounded="xxl">
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: red; font-size: 90px; width: 200px !important"
                >
                  mdi-delete-empty-outline
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: red">Deleted!</h1>
                      <br />
                      <h3>
                        The file has been deleted successfully. Now you can
                        select a new file to upload.
                      </h3>
                      <br />
                      <v-btn color="success" @click="is_Truncated = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
// import backbutton from "@/components/backbutton.vue";
export default {
  //   components: { backbutton },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Upload Pjp Excel",
  },
  data() {
    return {
      crud_pjps: [],
      isLoading: false,
      is_mail_sent: false,
      isUploading: false,
      is_Uploaded: false,
      isProcessing: false,
      is_Processed: false,
      is_Truncated: false,
      isError: false,
      error_msg: "",
      path: "",
    };
  },
  mounted() {
    this.getData();
    this.path = this.mediaUrl;
    console.log(this.path);
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      try {
        await axios
          .get(`/crud_pjps`)
          .then((crud_pjps) => {
            this.crud_pjps = crud_pjps.data.data;
            this.count = crud_pjps.data.count;
            this.serialNoStarting = (page - 1) * this.rowsPerPage;
            this.isLoading = false;
          })
          .catch(function (error) {
            console.log(error);
            window.location.href = "/auth/login";
          });
      } catch (error) {
        console.log(error);
      }
    },
    async handleFileUpload() {
      this.isUploading = true;
      this.isUploadingError = false;
      this.error_msg = "";
      this.pjps = this.$refs.file.files[0];
      let formData = new FormData();
      formData.append("pjps", this.pjps);
      this.changeValue = 100;
      await axios
        .post("crud_pjps/upload_pjp", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.data) this.crud_pjps = response.data.data;
          this.is_Uploaded = true;
        })
        .catch(() => {
          this.isError = true;
          this.error_msg =
            "Looks like system faced an error while uploading the File. Kindly check the file before uploading ...";
        });
      this.isUploading = false;
    },
    async processFile() {
      try {
        this.isProcessing = true;
        await axios.get(`crud_pjps/process_pjp`).catch(() => {
          this.isError = true;
          this.error_msg =
            "Looks like system faced an error while processing the File. Kindly check the file before processing ...";
        });
        this.isProcessing = false;
        this.is_Processed = true;
        await axios.get("crud_pjps/truncate_pjps");
        this.crud_pjps = [];
      } catch (e) {
        console.log(e);
      }
    },
    async truncatePendingDocuments() {
      try {
        await axios.get("crud_pjps/truncate_pjps");
        this.is_Truncated = true;
        this.crud_pjps = [];
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
td {
  min-width: 130px !important;
}
</style>
