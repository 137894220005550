<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn
            class="mr-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Add a Company
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.name"
                outlined
                label="Company Name *"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.tag_line"
                outlined
                label="Tag line"
                prepend-inner-icon="mdi-map-marker"
                :error-messages="errors.tag_line"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.email"
                outlined
                label="Email *"
                prepend-inner-icon="mdi-email"
                :error-messages="errors.email"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.phone"
                outlined
                label="Phone *"
                prepend-inner-icon="mdi-phone"
                :error-messages="errors.phone"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.admin_name"
                outlined
                label="Admin Name"
                prepend-inner-icon="mdi-card-account-phone"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.address"
                outlined
                label="Address *"
                prepend-inner-icon="mdi-map-marker-multiple"
                :error-messages="errors.address"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.city"
                outlined
                label="City"
                prepend-inner-icon="mdi-map-marker"
                :error-messages="errors.city"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.state"
                outlined
                label="State "
                prepend-inner-icon="mdi-map-marker"
                :error-messages="errors.state"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.pincode"
                outlined
                label="Pincode"
                prepend-inner-icon="mdi-map-marker"
                :error-messages="errors.pincode"
              ></v-text-field>
            </v-col>

            <v-col sm="4">
              <input
                type="file"
                id="file"
                name="file"
                :ref="`file`"
                @change="onFileChange"
              />
              <v-btn
                x-small
                class="reset_image my-1"
                color="red"
                dark
                @click="($refs.file.value = null), (image_url = null)"
                >Remove</v-btn
              >

              <div id="preview" v-if="image_url" class="mt-5">
                <label for="">Image Preview</label>
                <br />
                <img
                  :src="image_url"
                  style="height: 30%; width: 30% !important"
                />
              </div>
            </v-col>
          </v-row>
          <v-divider class="my-2"> </v-divider>
          <h3 class="my-4">List of Flag(s) for this Company:</h3>
          <v-row>
            <v-col sm="3">
              <v-switch
                class="mt-0"
                v-model="form.is_active"
                inset
                :label="`Is Active: ${form.is_active ? 'YES' : 'NO'}`"
              ></v-switch>
            </v-col>
            <v-col sm="3">
              <v-switch
                class="mt-0 ml-5"
                v-model="form.is_location_tracking_enabled"
                inset
                :label="`Is Location tracking Enabled: ${
                  form.is_location_tracking_enabled ? 'YES' : 'NO'
                }`"
              ></v-switch>
            </v-col>
            <v-col sm="3">
              <v-switch
                class="mt-0 ml-5"
                v-model="form.is_SUSP_enabled"
                inset
                :label="`Is SUSP Enabled: ${
                  form.is_SUSP_enabled ? 'YES' : 'NO'
                }`"
              ></v-switch>
            </v-col>
            <v-col sm="3">
              <v-switch
                class="mt-0 ml-5"
                v-model="form.is_SUSL_enabled"
                inset
                :label="`Is SUSL Enabled: ${
                  form.is_SUSL_enabled ? 'YES' : 'NO'
                }`"
              ></v-switch>
            </v-col>
          </v-row>
          <v-divider class="mt-5 mb-5"> </v-divider>
          <v-progress-circular
            v-if="isLoading"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-btn v-else rounded color="primary" dark @click="save">
            Save Company
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "CompanyCreatePage",
  data() {
    return {
      form: {
        address: "",
        admin_name: "",
        city: "",
        code: "",
        email: "",
        is_active: 1,
        name: "",
        phone: "",
        pincode: "",
        state: "",
        whatsapp_no: "",
      },
      isLoading: false,
      image_url: "",
    };
  },

  methods: {
    async save() {
      try {
        this.isLoading = true;
        this.form.is_active = this.form.is_active ? 1 : 0;
        let formTest = {};
        Object.assign(formTest, this.form);
        let formData = this.jsonToFormData(formTest);
        let attachment = this.$refs.file.files[0];
        // formData.append("id", this.form.id);
        formData.append("logo_path", attachment);
        await axios
          .post("/companies", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(async (res) => {
            this.form = res.data.data;
            // await this.handleFileUpload();
            this.isLoading = false;
            await this.saveUserTimestamp(null, this.form);
            this.$router.push("/companies");
          })
          .catch(function (error) {
          console.log(error);
            // redirect to Home Page
            window.location.href = "/auth/login";
          });
      } catch (e) {
        // alert("error");
        this.isLoading = false;
      }
    },

    jsonToFormData(data) {
      const formData = new FormData();
      this.buildFormData(formData, data);
      return formData;
    },
    buildFormData(formData, data, parentKey) {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;
        formData.append(parentKey, value);
      }
    },
    async handleFileUpload() {
      if (this.$refs.file != null) {
        let attachment = this.$refs.file.files[0];
        let formData = new FormData();
        formData.append("id", this.form.id);
        formData.append("logo_path", attachment);
        await axios
          .post("upload_company_logo_path", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .catch(function (error) {
          console.log(error);
            console.log("FAILURE!!");
          });
      }
    },
    onFileChange() {
      this.warningImageLoader = false;
      this.file = this.$refs.file?.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = (evt) => {
        let img = new Image();
        img.onload = () => {
          this.imageLoader = false;
          this.image_url = URL.createObjectURL(this.file);
          this.imageErrors = "";
        };
        img.src = evt.target.result;
      };

      reader.onerror = (evt) => {
        console.error(evt);
      };
      // this.image_url = URL.createObjectURL(this.file);
    },
  },
};
</script>
