<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-title>
              <h3 class="font-weight-bold" v-text="'Agents'"></h3>
              <v-btn
                class="mx-2 mb-2"
                fab
                x-small
                color="primary"
                to="/agents/create"
              >
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field
                    solo
                    rounded
                    v-model="filter.search_keyword"
                    label="Search ... "
                    color="#e60040"
                    prepend-inner-icon="mdi-magnify"
                    clearable
                    @click:clear="
                      (filter.search_keyword = null), (page = 1), getData()
                    "
                    @keydown.enter="(page = 1), getData()"
                  >
                  </v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete
                    ref="Country"
                    solo
                    rounded
                    v-model="filter.country_id"
                    :items="countryItems"
                    clearable
                    @click:clear="(filter.country_id = null), (page = 1)"
                    label="Country"
                    @input="getData"
                    prepend-inner-icon="mdi-account"
                  ></v-autocomplete>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete
                    ref="status"
                    solo
                    rounded
                    v-model="filter.is_active"
                    :items="statuses"
                    clearable
                    @click:clear="(filter.is_active = null), (page = 1)"
                    label="Status"
                    @input="getData"
                    prepend-inner-icon="mdi-toggle-switch"
                  ></v-autocomplete>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete
                    ref="Approval_status"
                    solo
                    rounded
                    v-model="filter.status"
                    :items="approvalStatuses"
                    clearable
                    @click:clear="(filter.status = null), (page = 1)"
                    label="Approval Status"
                    @input="getData"
                    prepend-inner-icon="mdi-toggle-switch"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-text>
              <section v-if="isLoading" style="height: 285px">
                <v-progress-linear
                  indeterminate
                  color="primary"
                ></v-progress-linear>
              </section>
              <v-simple-table
                v-else
                fixed-header
                height="285"
                class="striped-table"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left sr_no">Sr No</th>
                      <th class="text-left">Status</th>
                      <th class="text-left">Approval Status</th>
                      <th class="text-left" style="min-width: 150px">Name</th>
                      <th class="text-left" style="min-width: none">Country</th>
                      <th class="text-left" style="min-width: none">
                        Primary Contact No
                      </th>
                      <th class="text-left" style="min-width: none">
                        Secondary Contact No
                      </th>
                      <th class="text-left" style="min-width: none">Address</th>
                      <th class="text-left" style="min-width: none">Pincode</th>
                      <th class="text-left" style="min-width: none">Percent</th>
                      <th class="text-left" style="min-width: none">Pan no</th>
                      <th class="text-left" style="min-width: none">
                        Pan Image
                      </th>
                      <th class="text-left" style="min-width: none">
                        Cancel Cheque
                      </th>
                      <th class="text-right action_th">Action</th>
                    </tr>
                  </thead>
                  <tbody v-if="agents.length != 0">
                    <tr
                      v-for="(agent, i) in agents"
                      :key="`agent_${i}`"
                      :class="{
                        'table-row-even': i % 2 === 0,
                        'table-row-odd': i % 2 !== 0,
                        'table-row-last': agents.length - 1 == i,
                      }"
                    >
                      <td style="min-width: 85px">
                        {{ i + 1 }}
                      </td>
                      <td>
                        <v-tooltip bottom v-if="agent.is_active == 1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="teal"
                              text-color="white"
                            >
                              <v-avatar left>
                                <v-icon size="15"
                                  >mdi-checkbox-marked-circle</v-icon
                                >
                              </v-avatar>
                              Active
                            </v-chip>
                          </template>
                          <span>Agent is Active</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="agent.is_active == 0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="red"
                              text-color="white"
                            >
                              <v-avatar left>
                                <v-icon size="15">mdi-close-circle</v-icon>
                              </v-avatar>
                              Inactive
                            </v-chip>
                          </template>
                          <span>Agent is InActive</span>
                        </v-tooltip>
                      </td>
                      <td>
                        <v-tooltip bottom v-if="agent.status == 1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="teal"
                              text-color="white"
                            >
                              <v-avatar left>
                                <v-icon size="15">mdi-check-decagram</v-icon>
                              </v-avatar>
                              Approved
                            </v-chip>
                          </template>
                          <span>Agent is Approved</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="agent.status == 0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="yellow"
                              text-color="black"
                            >
                              <v-avatar left>
                                <v-icon size="15">mdi-close-circle</v-icon>
                              </v-avatar>
                              Pending
                            </v-chip>
                          </template>
                          <span>Agent is Pending for Approval</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="agent.status == 2">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="red"
                              text-color="white"
                            >
                              <v-avatar left>
                                <v-icon size="15">mdi-close-circle</v-icon>
                              </v-avatar>
                              Rejected
                            </v-chip>
                          </template>
                          <span>Agent has been Rejected</span>
                        </v-tooltip>
                      </td>
                      <td>{{ agent.name || "" }}</td>
                      <td>{{ agent.country.description || "" }}</td>
                      <td>{{ agent.primary_contact_no || "" }}</td>
                      <td>{{ agent.secondary_contact_no || "" }}</td>
                      <td>{{ agent.address || "" }}</td>
                      <td>{{ agent.pincode || "" }}</td>
                      <td>{{ agent.percent || "" }}</td>
                      <td>{{ agent.pan_no || "" }}</td>
                      <td>
                        <a
                          v-if="agent.pan_imagepath"
                          :href="mediaUrl + agent.pan_imagepath"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <v-img
                            :src="`${mediaUrl + agent.pan_imagepath}`"
                            contain
                            style="height: 100px; width: 100px !important"
                          />
                        </a>
                      </td>
                      <td>
                        <a
                          v-if="agent.cancel_cheque_imagepath"
                          :href="mediaUrl + agent.cancel_cheque_imagepath"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <v-img
                            :src="`${mediaUrl + agent.cancel_cheque_imagepath}`"
                            contain
                            style="height: 100px; width: 100px !important"
                          />
                        </a>
                      </td>
                      <td class="text-right">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              fab
                              x-small
                              class="my-auto"
                              color="error"
                              @click="Delete_this(agent.id)"
                            >
                              <v-icon dark> mdi-delete </v-icon>
                            </v-btn>
                          </template>
                          <span>Delete this Agent</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              class="ml-2"
                              fab
                              x-small
                              color="primary"
                              :to="`/agents/${encryptIt(agent.id)}`"
                            >
                              <v-icon dark> mdi-pencil </v-icon>
                            </v-btn>
                          </template>
                          <span>Edit this Agent</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="14" class="text-center">No data found</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-pagination rounded v-model="page" :length="paginationLength">
              </v-pagination>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Clear Success Dialog Box -->
      <v-dialog v-model="is_deleted_agent" width="600">
        <v-card rounded="xxl">
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: green; font-size: 90px; width: 200px !important"
                >
                  mdi-delete-empty
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>Agent deleted successfully.</h3>
                      <br />
                      <v-btn color="success" @click="is_deleted_agent = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "AgentsPage",
  data() {
    return {
      filter: {
        search_keyword: "",
        is_active: "",
      },
      agents: [],
      countryItems: [],
      agentTypeItems: [],
      accountGroupItems: [],
      statuses: [
        {
          id: 0,
          text: "Select Active Status",
          value: "",
        },
        { id: "ACTIVE", text: "ACTIVE", value: "1" },
        { id: "INACTIVE", text: "INACTIVE", value: "0" },
      ],
      approvalStatuses: [
        { id: "PENDING", text: "PENDING", value: "0" },
        { id: "APPROVED", text: "APPROVED", value: "1" },
        { id: "REJECTED", text: "REJECTED", value: "2" },
      ],
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      isLoading: false,
      delete_agent: false,
      is_deleted_agent: false,
    };
  },
  watch: {
    page: "getData",
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      await axios
        .get(`agents/masters`)
        .then((masters) => {
          masters = masters.data;
          //Countries
          masters.countries.forEach((country) => {
            this.countryItems.push({
              id: country.id,
              text: country.description,
              value: country.id,
            });
          });
          //Agent Type
          masters.agent_types.forEach((agent_type) => {
            this.agentTypeItems.push({
              id: agent_type.id,
              text: agent_type.description,
              value: agent_type.id,
            });
          });
          //Account Group
          masters.account_groups.forEach((account_group) => {
            this.accountGroupItems.push({
              id: account_group.id,
              text: account_group.name,
              value: account_group.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          window.location.href = "/auth/login";
        });
      this.getData();
    },
    async getData() {
      this.isLoading = true;
      this.filter.search_keyword = this.filter.search_keyword
        ? this.filter.search_keyword
        : "";
      this.filter.is_active = this.filter.is_active
        ? this.filter.is_active
        : "";
      this.filter.status = this.filter.status ? this.filter.status : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&is_active=" +
        this.filter.is_active +
        "&status=" +
        this.filter.status +
        "&search_keyword=" +
        this.filter.search_keyword;
      await axios
        .get(`agents?${query}`)
        .then((response) => {
          this.agents = response.data.data;
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
    async Delete_this(id) {
      this.delete_agent = true;
      await axios
        .post(`/agents/delete/${this.encryptIt(id)}`)
        .then(() => {
          this.delete_agent = false;
          this.is_deleted_agent = true;
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
  },
};
</script>
