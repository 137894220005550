<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn
            class="mr-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Edit Dispatch for {{ CurrentFabricType }} [ID : {{ fabric_po.id }}]
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="3">
              <v-menu
                v-model="form.date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.date"
                    label="Date *"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    hide-details="auto"
                    @click:clear="form.date = null"
                    :error-messages="errors.date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.date"
                  @change="form.date_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="3">
              <v-text-field
                v-model="form.bill_no"
                outlined
                readonly
                label="Bill No"
                prepend-inner-icon="mdi-invoice-list-outline"
              ></v-text-field>
            </v-col>
            <v-col sm="3">
              <label for="Pan">Upload Bill</label>
              <br />
              <input
                label="Bill"
                type="file"
                id="bill_file"
                name="bill_file"
                :ref="`bill_file`"
              />
              <v-btn
                x-small
                class="reset_image my-1"
                color="red"
                dark
                @click="$refs.bill_file.value = null"
                >Remove</v-btn
              >
            </v-col>
            <v-col sm="3">
              <v-autocomplete
                ref="transporter_id"
                outlined
                v-model="form.transporter_id"
                :items="transportationItems"
                label="Transportation *"
                prepend-inner-icon="mdi-bus"
                clearable
                :error-messages="errors.transporter_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="3">
              <v-text-field
                v-model="form.meters"
                outlined
                readonly
                label="Dispatched Meters"
                prepend-inner-icon="mdi-diameter-outline"
              ></v-text-field>
            </v-col>
            <v-col sm="3">
              <v-text-field
                v-model="form.lr_no"
                outlined
                label="LR No"
                prepend-inner-icon="mdi-diameter-outline"
              ></v-text-field>
            </v-col>
            <v-col sm="3">
              <v-menu
                v-model="form.lr_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.lr_date"
                    label="LR Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    hide-details="auto"
                    @click:clear="form.lr_date = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.lr_date"
                  @change="form.lr_date_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="3">
              <v-text-field
                v-model="form.rolls"
                outlined
                readonly
                label="Total Roll"
                prepend-inner-icon="mdi-paper-roll"
              ></v-text-field>
            </v-col>
            <v-col sm="3">
              <v-text-field
                v-model="form.pieces"
                outlined
                readonly
                label="Total Pieces"
                prepend-inner-icon="mdi-hexagon-multiple-outline"
              ></v-text-field>
            </v-col>
            <v-col sm="3">
              <v-text-field
                v-model="form.gross_weight"
                outlined
                readonly
                label="Total Gross Weight"
                prepend-inner-icon="mdi-weight-kilogram"
              ></v-text-field>
            </v-col>
            <v-col sm="3">
              <v-text-field
                v-model="form.net_weight"
                outlined
                readonly
                label="Total Net Weight"
                prepend-inner-icon="mdi-weight-kilogram"
              ></v-text-field>
            </v-col>
            <v-col sm="3" v-if="roleName == 'ADMIN'">
              <v-autocomplete
                ref="status"
                outlined
                v-model="form.status"
                :items="statusDetails"
                label="Status *"
                prepend-inner-icon="mdi-earth"
                :error-messages="errors.status"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="form.status == 1">
            <v-col sm="3">
              <v-text-field
                v-model="form.lot_no"
                outlined
                label="Lot No"
                prepend-inner-icon="mdi-diameter-outline"
              ></v-text-field>
            </v-col>
            <v-col sm="3">
              <v-menu
                v-model="form.recieved_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.recieved_date"
                    label="Recieved Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    hide-details="auto"
                    @click:clear="form.recieved_date = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.recieved_date"
                  @change="form.recieved_date_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <template v-if="form.fabric_po_dispatch_details.length">
            <h3 class="my-4">List of Package Detail(s) for this Dispatch:</h3>
            <section
              v-for="(detail, fpDD) in form.fabric_po_dispatch_details"
              :key="`detail${fpDD}`"
            >
              <v-row>
                <v-col sm="3">
                  <template v-if="CurrentFabricType == 'FABRIC PO'">
                    <v-text-field
                      v-model="detail.rank"
                      outlined
                      dense
                      @input="Calculate('ROLL')"
                      label="Roll No / Bale No *"
                      prepend-inner-icon="mdi-paper-roll"
                      :error-messages="
                        errors[`fabric_po_dispatch_details.${fpDD}.rank`]
                          ? errors[`fabric_po_dispatch_details.${fpDD}.rank`]
                          : ``
                      "
                    ></v-text-field>
                  </template>
                  <template v-else>
                    <template v-if="detail.id">
                      <v-text-field
                        v-model="detail.rank"
                        outlined
                        dense
                        readonly
                        label="Roll No / Bale No *"
                        hint="Fabric PO | Quality | Roll no | Remaining meters"
                        prepend-inner-icon="mdi-paper-roll"
                        :error-messages="
                          errors[`fabric_po_dispatch_details.${fpDD}.rank`]
                            ? errors[`fabric_po_dispatch_details.${fpDD}.rank`]
                            : ``
                        "
                      ></v-text-field>
                    </template>
                    <template v-else>
                      <v-autocomplete
                        ref="transporter_id"
                        outlined
                        dense
                        v-model="detail.fabric_po_dispatch_detail_id"
                        :items="roleItems"
                        label="Roll No / Bale No *"
                        hint="Fabric PO | Quality | Roll no | Remaining meters"
                        prepend-inner-icon="mdi-bus"
                        clearable
                        @input="Calculate('ROLL'), fillData(detail)"
                        :error-messages="
                          errors[`fabric_po_dispatch_details.${fpDD}.rank`]
                            ? errors[`fabric_po_dispatch_details.${fpDD}.rank`]
                            : ``
                        "
                      ></v-autocomplete>
                    </template>
                  </template>
                </v-col>
                <v-col sm="2">
                  <v-text-field
                    v-model="detail.pieces"
                    outlined
                    @input="Calculate('PIECE')"
                    dense
                    label="Pieces *"
                    prepend-inner-icon="mdi-hexagon-multiple-outline"
                    :error-messages="
                      errors[`fabric_po_dispatch_details.${fpDD}.pieces`]
                        ? errors[`fabric_po_dispatch_details.${fpDD}.pieces`]
                        : ``
                    "
                  ></v-text-field>
                </v-col>
                <v-col sm="2">
                  <v-text-field
                    v-model="detail.meters"
                    outlined
                    dense
                    @input="Calculate('METER')"
                    label="Meters *"
                    prepend-inner-icon="mdi-diameter-outline"
                    :error-messages="
                      errors[`fabric_po_dispatch_details.${fpDD}.meters`]
                        ? errors[`fabric_po_dispatch_details.${fpDD}.meters`]
                        : ``
                    "
                  ></v-text-field>
                </v-col>
                <v-col sm="2">
                  <v-text-field
                    v-model="detail.gross_weight"
                    outlined
                    dense
                    @input="Calculate('GROSS')"
                    label="Gross Weight *"
                    prepend-inner-icon="mdi-weight-kilogram"
                    :error-messages="
                      errors[`fabric_po_dispatch_details.${fpDD}.gross_weight`]
                        ? errors[
                            `fabric_po_dispatch_details.${fpDD}.gross_weight`
                          ]
                        : ``
                    "
                  ></v-text-field>
                </v-col>
                <v-col sm="2">
                  <v-text-field
                    v-model="detail.net_weight"
                    outlined
                    dense
                    @input="Calculate('NET')"
                    label="Net Weight *"
                    prepend-inner-icon="mdi-weight-kilogram"
                    :error-messages="
                      errors[`fabric_po_dispatch_details.${fpDD}.net_weight`]
                        ? errors[
                            `fabric_po_dispatch_details.${fpDD}.net_weight`
                          ]
                        : ``
                    "
                  ></v-text-field>
                </v-col>
                <v-col sm="1">
                  <template v-if="!detail.id">
                    <v-dialog
                      v-model="isDeleteDetailDialogs[fpDD]"
                      max-width="600px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          fab
                          small
                          dark
                          color="red"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon dark> mdi-close</v-icon>
                        </v-btn>
                      </template>
                      <v-card elevation="10">
                        <v-card-title>
                          <v-icon large color="primary">
                            mdi-information-outline
                          </v-icon>
                          &nbsp; Do you want to remove the section?
                        </v-card-title>
                        <v-card-text>
                          <v-btn
                            color="primary"
                            @click.stop="deleteDetail(detail)"
                          >
                            Yes
                          </v-btn>
                          &nbsp;
                          <v-btn
                            color="red"
                            dark
                            @click.stop="isDeleteDetailDialogs = []"
                          >
                            No
                          </v-btn>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </template>
                </v-col>
              </v-row>
              <v-divider class="my-5"> </v-divider>
            </section>
          </template>
          <v-row>
            <v-col sm="12" class="mb-5">
              <v-btn small rounded color="primary" @click="Add_Empty_Detail">
                Add Package Detail
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <v-btn
            rounded
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="save"
          >
            Save Dispatch
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";

export default {
  name: "StateEditPage",
  data() {
    return {
      form: {
        date: "",
        type: "",
        meters: 0,
        fabric_po_dispatch_details: [],
      },
      fabric_po: {},
      transportationItems: [],
      current_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      statusDetails: [
        { id: "PENDING", text: "PENDING", value: 0 },
        { id: "RECIEVED", text: "RECIEVED", value: 1 },
      ],
      isDeleteDetailDialogs: [],
      isLoading: false,
      CurrentFabricType: "",
      lr_date_menu: false,
      recieved_date_menu: false,
      roleItems: [],
    };
  },
  mounted() {
    this.CurrentFabricType =
      this.$route.name == "FabricSoDispatchIdPage" ? "FABRIC SO" : "FABRIC PO";
    this.getData();
  },
  methods: {
    async getData() {
      let encryptedId = this.$route.params.fabricPoDispatchId;
      axios
        .get(`/fabric_po_dispatches/${encryptedId}`)
        .then((response) => {
          this.form = response.data.data;
          this.form.fabric_po_dispatch_details.forEach((detail) => {
            detail.fabric_po_dispatch_detail_id = parseInt(
              detail.fabric_po_dispatch_detail_id
            );
          });

          this.form.type = this.CurrentFabricType == "FABRIC SO" ? 1 : 0;
          console.log(this.form);

          this.fabric_po = this.form.fabric_po;
          this.getMasters();
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    async getMasters() {
      let qualityIdArray = this.fabric_po.fabric_po_items
        .map((v) => v.quality_id)
        .join(",");
      console.log(qualityIdArray);

      await axios
        .get(
          `/fabric_po_dispatches/masters?quality_ids=${qualityIdArray}&type=0`
        )
        .then((masters) => {
          //Transportations
          masters.data.transportations.forEach((t) => {
            this.transportationItems.push({
              id: t.id,
              text: t.name,
              value: t.id,
            });
          });
          if (this.CurrentFabricType == "FABRIC SO") {
            masters.data.fabric_pos.forEach((po) => {
              po.fabric_po_dispatches.forEach((dispatch) => {
                dispatch.remaining_rolls.forEach((detail) => {
                  let roll = detail;
                  roll.fabric_po_id = "";
                  roll.fabric_po_dispatch_detail_id = detail.id;
                  roll.text =
                    po.id +
                    " | " +
                    po.fabric_po_items[0].quality.quality_description +
                    " | " +
                    detail.rank +
                    " | " +
                    detail.remaining_meter;
                  roll.value = detail.id;
                  this.roleItems.push(roll);
                });
              });
              console.log(this.roleItems);
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    Add_Empty_Detail() {
      let fabric_po_dispatch_detail = {
        igst_percent: "",
        igst_amount: 0,
        cgst_percent: "",
        cgst_amount: 0,
        sgst_percent: "",
        sgst_amount: 0,
        gst_amount: 0,
        delivery_date: "",
        delivery_date_menu: false,
      };
      this.form.fabric_po_dispatch_details.push(fabric_po_dispatch_detail);
    },
    deleteDetail(fabric_po_dispatch_detail) {
      this.form.fabric_po_dispatch_details.splice(
        this.form.fabric_po_dispatch_details.indexOf(fabric_po_dispatch_detail),
        1
      );
      this.isDeleteDetailDialogs = [];
    },
    Calculate(type) {
      if (type == "ROLL") {
        this.form.rolls = this.form.fabric_po_dispatch_details.length;
      }
      if (type == "PIECE") {
        this.form.pieces = 0;
        this.form.fabric_po_dispatch_details.forEach((detail) => {
          this.form.pieces += detail.pieces ? parseFloat(detail.pieces) : 0;
        });
      }
      if (type == "METER") {
        this.form.meters = 0;
        this.form.fabric_po_dispatch_details.forEach((detail) => {
          this.form.meters += detail.meters ? parseFloat(detail.meters) : 0;
        });
      }
      if (type == "GROSS") {
        this.form.gross_weight = 0;
        this.form.fabric_po_dispatch_details.forEach((detail) => {
          this.form.gross_weight += detail.gross_weight
            ? parseFloat(detail.gross_weight)
            : 0;
        });
      }
      if (type == "NET") {
        this.form.net_weight = 0;
        this.form.fabric_po_dispatch_details.forEach((detail) => {
          this.form.net_weight += detail.net_weight
            ? parseFloat(detail.net_weight)
            : 0;
        });
      }
    },
    CalculateIt() {
      this.form.rolls = this.form.fabric_po_dispatch_details.length;
      this.form.pieces = 0;
      this.form.meters = 0;
      this.form.gross_weight = 0;
      this.form.net_weight = 0;
      this.form.fabric_po_dispatch_details.forEach((detail) => {
        this.form.pieces += detail.pieces ? parseFloat(detail.pieces) : 0;
        this.form.meters += detail.meters ? parseFloat(detail.meters) : 0;
        this.form.gross_weight += detail.gross_weight
          ? parseFloat(detail.gross_weight)
          : 0;
        this.form.net_weight += detail.net_weight
          ? parseFloat(detail.net_weight)
          : 0;
      });
    },
    fillData(detail) {
      let used_roll = this.roleItems.find(
        (r) => r.id == detail.fabric_po_dispatch_detail_id
      );
      detail.rank = used_roll.text;
      detail.pieces = used_roll.pieces;
      detail.meters = used_roll.remaining_meter;
      detail.gross_weight = used_roll.gross_weight;
      detail.net_weight = used_roll.net_weight;
      this.CalculateIt();
    },
    async save() {
      try {
        this.isLoading = true;
        await axios
          .post("/fabric_po_dispatches", this.form)
          .then(async (response) => {
            this.form = response.data.data;
            let attachment = this.$refs.bill_file.files[0];

            await this.handleFileUpload(
              this.form.id,
              "bill_imagepath",
              attachment,
              "upload_fabric_po_dispatch_bill_imagepath"
            );
            this.isLoading = false;
            await this.saveUserTimestamp(null, this.form);
            if (this.CurrentFabricType == "FABRIC SO") {
              this.$router.push(
                `/fabric-sos/${this.encryptIt(
                  this.form.fabric_po_id
                )}/fabric-so-dispatches`
              );
            } else {
              this.$router.push(
                `/fabric-pos/${this.encryptIt(
                  this.form.fabric_po_id
                )}/fabric-po-dispatches`
              );
            }
          })
          .catch((error) => {
            // redirect to Home Page
            // window.location.href = "/auth/login";
            console.error("Error fetching data:", error);
          });
      } catch (e) {
        // alert("error");
        console.log(e);
        this.isLoading = false;
      }
      this.isLoading = false;
    },
  },
};
</script>
