<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn
            class="mr-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Add Consignee
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete
                ref="state"
                outlined
                v-model="form.state_id"
                :items="stateItems"
                label="State"
                prepend-inner-icon="mdi-earth"
                clearable
                @input="getStateWiseCity(form)"
                :error-messages="errors.state_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="city"
                :disabled="!form.state_id"
                outlined
                v-model="form.city_id"
                :items="form.cityItems"
                label="City"
                prepend-inner-icon="mdi-earth"
                clearable
                :error-messages="errors.city_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.name"
                outlined
                label="Name *"
                :error-messages="errors.name"
                prepend-inner-icon="mdi-account-cog"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.address_line_1"
                outlined
                label="Address Line 1"
                prepend-inner-icon="mdi-map-marker"
                :error-messages="errors.address_line_1"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.address_line_2"
                outlined
                label="Address Line 2"
                prepend-inner-icon="mdi-map-marker"
                :error-messages="errors.address_line_2"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.gstn"
                outlined
                label="GSTN"
                prepend-inner-icon="mdi-percent"
                :error-messages="errors.gstn"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.pincode"
                outlined
                label="Pincode"
                prepend-inner-icon="mdi-earth"
                :error-messages="errors.pincode"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.contact_no"
                outlined
                label="Contact No"
                prepend-inner-icon="mdi-phone"
                :error-messages="errors.contact_no"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-switch
                class="mt-0 ml-5"
                v-model="form.is_active"
                inset
                :label="`Is Active: ${form.is_active ? 'YES' : 'NO'}`"
              ></v-switch>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <v-btn
            rounded
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="save"
          >
            Save Consignee
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "ConsigneeCreatePage",
  data() {
    return {
      form: {
        is_active: true,
        consignee_type: "",
        cityItems: [],
      },
      stateItems: [],
      allcityItems: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      await axios
        .get(`consignees/masters`)
        .then((masters) => {
          masters = masters.data;
          //State
          masters.states.forEach((state) => {
            this.stateItems.push({
              id: state.id,
              text: state.name,
              value: state.id,
            });
          });
          //City
          masters.cities.forEach((city) => {
            this.allcityItems.push({
              id: city.id,
              text: city.name,
              value: city.id,
              state_id: city.state_id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          // window.location.href = "/auth/login";
        });
    },
    getStateWiseCity(parent) {
      parent.city_id = "";
      parent.cityItems = [];
      parent.cityItems = this.allcityItems.filter((item) => {
        return item.state_id == parent.state_id;
      });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios
          .post("/consignees", this.form)
          .then(async () => {
            await this.saveUserTimestamp(null, this.form);
            this.isLoading = false;
            this.$router.push("/consignees");
          })
          .catch((error) => {
            // redirect to Home Page
            if (error.response.status != 422) {
              window.location.href = "/auth/login";
            }
            console.error("Error fetching data:", error);
          });
      } catch (e) {
        // alert("error");
        console.log(e);
        this.isLoading = false;
      }
      this.isLoading = false;
    },
  },
};
</script>
