<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn
            class="mr-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Edit Account Sub Type
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete
                ref="account_type_id"
                outlined
                v-model="form.account_type_id"
                :items="accountTypeItems"
                label="Account Type *"
                prepend-inner-icon="mdi-earth"
                clearable
                :error-messages="errors.account_type_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.name"
                outlined
                label="Name *"
                prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-switch
                class="mt-0 ml-5"
                v-model="form.is_active"
                inset
                :label="`Is Active: ${form.is_active ? 'YES' : 'NO'}`"
              ></v-switch>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <v-btn
            rounded
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="save"
          >
            Update Account Sub Type
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";

export default {
  name: "accountSubTypeEditPage",
  data() {
    return {
      form: {},
      accountTypeItems: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      await axios
        .get(`account_sub_types/masters`)
        .then((masters) => {
          masters = masters.data;
          //Users
          masters.account_types.forEach((aT) => {
            this.accountTypeItems.push({
              id: aT.id,
              text: aT.description,
              value: aT.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          // window.location.href = "/auth/login";
        });
    },
    async getData() {
      this.isLoading = true;
      let encryptedId = this.$route.params.accountSubTypeId;
      axios
        .get(`/account_sub_types/${encryptedId}`)
        .then((response) => {
          this.form = response.data.data;
          this.old_json = response.data.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        await axios
          .patch(
            `/account_sub_types/${this.$route.params.accountSubTypeId}`,
            this.form
          )
          .then(async () => {
            await this.saveUserTimestamp(null, this.form);
            this.isLoading = false;
            this.$router.push("/account-sub-types");
          })
          .catch((error) => {
            // redirect to Home Page
            window.code.href = "/auth/login";
            console.error("Error fetching data:", error);
          });
      } catch (e) {
        // alert("error");
        console.log(e);
        this.isLoading = false;
      }
  this.isLoading = false;
    },
  },
};
</script>
