<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn
            class="mr-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Edit Quality
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete
                ref="fabric_type"
                outlined
                dense
                v-model="form.fabric_type"
                :items="fabricTypeItems"
                label="Fabric Type *"
                prepend-inner-icon="mdi-earth"
                clearable
                :error-messages="errors.fabric_type"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="color"
                outlined
                dense
                v-model="form.color_id"
                :items="colorItems"
                label="Color"
                prepend-inner-icon="mdi-earth"
                clearable
                :error-messages="errors.color_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-textarea
                outlined
                dense
                rows="1"
                v-model="form.sort_number"
                label="Sort Number/Name *"
                :error-messages="errors.sort_number"
              ></v-textarea>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.warpweft"
                outlined
                dense
                label="WarpWeft *"
                prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.warpweft"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="weave_type"
                outlined
                dense
                v-model="form.weave_type_id"
                :items="weaveTypeItems"
                label="Weave Type *"
                prepend-inner-icon="mdi-earth"
                clearable
                :error-messages="errors.weave_type_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-textarea
                outlined
                dense
                rows="1"
                v-model="form.quality_description"
                label="Quality Description *"
                :error-messages="errors.quality_description"
              ></v-textarea>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.gsm"
                outlined
                dense
                label="GSM"
                prepend-inner-icon="mdi-card-text-outline"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.composition"
                outlined
                dense
                label="Composition"
                prepend-inner-icon="mdi-card-text-outline"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.width"
                outlined
                dense
                label="Width *"
                prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.width"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <input
                type="file"
                id="design_paper_file"
                name="design_paper_file"
                :ref="`design_paper_file`"
                accept="image/*"
                @change="onFileChange"
              />
              <v-btn
                x-small
                class="reset_image my-1"
                color="red"
                dark
                @click="
                  ($refs.design_paper_file.value = null),
                    (form.design_paper_imagepath = null),
                    (design_image_url = null)
                    "
                >Remove</v-btn
                >
                
                <div id="preview" v-if="design_image_url" class="mt-5">
                  <label for="">Image Preview</label>
                  <br />
                  <img
                  :src="design_image_url"
                  style="height: 30%; width: 30% !important"
                />
              </div>
            </v-col>
            <v-col sm="4">
              <input
              type="file"
              id="fabric_file"
              name="fabric_file"
              accept="image/*"
              :ref="`fabric_file`"
                @change="onFileChange"
                />
                <v-btn
                x-small
                class="reset_image my-1"
                color="red"
                dark
                @click="
                  ($refs.fabric_file.value = null),
                  (form.fabric_imagepath = null),
                  (fabric_image_url = null)
                  "
                >Remove</v-btn
              >

              <div id="preview" v-if="fabric_image_url" class="mt-5">
                <label for="">Image Preview</label>
                <br />
                <img
                  :src="fabric_image_url"
                  style="height: 30%; width: 30% !important"
                />
              </div>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="quality_for"
                outlined
                dense
                v-model="form.quality_for"
                :items="qualityForItems"
                label="Quality For"
                prepend-inner-icon="mdi-map-marker"
                clearable
                :error-messages="errors.quality_for"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <v-row>
            <v-col sm="3">
              <v-text-field
                v-model="form.hsn_code"
                outlined
                dense
                label="HSN Code"
                prepend-inner-icon="mdi-card-text-outline"
              ></v-text-field>
            </v-col>
            <v-col sm="3">
              <v-autocomplete
                ref="igst"
                outlined
                dense
                v-model="form.igst_percent"
                :items="igstItems"
                label="IGST %"
                prepend-inner-icon="mdi-earth"
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col sm="3">
              <v-autocomplete
                ref="cgst"
                outlined
                dense
                v-model="form.cgst_percent"
                :items="cgstItems"
                label="CGST %"
                prepend-inner-icon="mdi-earth"
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col sm="3">
              <v-autocomplete
                ref="sgst"
                outlined
                dense
                v-model="form.sgst_percent"
                :items="sgstItems"
                label="SGST %"
                prepend-inner-icon="mdi-earth"
                clearable
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <v-btn
            rounded
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="save"
          >
            Update Quality
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "QualityEditPage",
  data() {
    return {
      form: {},
      colorItems: [],
      igstItems: [],
      cgstItems: [],
      sgstItems: [],
      fabricTypeItems: [
        { id: "FINISHED", text: "FINISHED", value: 1 },
        { id: "GREY", text: "GREY", value: 2 },
      ],
      qualityForItems: [
        { id: "EXPORT", text: "EXPORT", value: 1 },
        { id: "DOMESTIC", text: "DOMESTIC", value: 2 },
        { id: "BOTH", text: "BOTH", value: 3 },
      ],
      weaveTypeItems: [],
      current_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      date_menu: false,
      isLoading: false,
      warningImageLoader: "",
      design_paper_file: "",
      design_image_url: "",
      fabric_file: "",
      fabric_image_url: "",
      imageLoader: "",
      imageErrors: "",
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      await axios
        .get(`qualities/masters`)
        .then((masters) => {
          masters = masters.data;
          //Color
          masters.colors.forEach((color) => {
            this.colorItems.push({
              id: color.id,
              text: color.description,
              value: color.id,
            });
          });
          //Color
          masters.weave_types.forEach((weave_type) => {
            this.weaveTypeItems.push({
              id: weave_type.id,
              text: weave_type.description,
              value: weave_type.id,
            });
          });
          //GST
          masters.gsts.forEach((gst) => {
            if (gst.gst_type == 1) {
              this.igstItems.push({
                id: gst.id,
                text: gst.igst_percent,
                value: gst.igst_percent,
                gst_type: gst.gst_type,
              });
            } else {
              this.cgstItems.push({
                id: gst.id,
                text: gst.cgst_percent,
                value: gst.cgst_percent,
                gst_type: gst.gst_type,
              });
              this.sgstItems.push({
                id: gst.id,
                text: gst.sgst_percent,
                value: gst.sgst_percent,
                gst_type: gst.gst_type,
              });
            }
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          window.location.href = "/auth/login";
        });
      await this.getData();
    },
    async getData() {
      this.isLoading = true;
      let encryptedId = this.$route.params.qualityId;
      axios
        .get(`/qualities/${encryptedId}`)
        .then((response) => {
          this.form = response.data.data;
          this.old_json = response.data.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      this.isLoading = false;
    },
    onFileChange() {
      this.warningImageLoader = false;
      this.design_paper_file = this.$refs.design_paper_file?.files[0];
      if (this.design_paper_file) {
        let reader = new FileReader();
        reader.readAsDataURL(this.design_paper_file);
        reader.onload = (evt) => {
          let img = new Image();
          img.onload = () => {
            this.imageLoader = false;
            this.design_image_url = URL.createObjectURL(this.design_paper_file);
            this.imageErrors = "";
          };
          img.src = evt.target.result;
        };

        reader.onerror = (evt) => {
          console.error(evt);
        };
      }
      this.fabric_file = this.$refs.fabric_file?.files[0];
      if (this.fabric_file) {
        let reader = new FileReader();
        reader.readAsDataURL(this.fabric_file);
        reader.onload = (evt) => {
          let img = new Image();
          img.onload = () => {
            this.imageLoader = false;
            this.fabric_image_url = URL.createObjectURL(this.fabric_file);
            this.imageErrors = "";
          };
          img.src = evt.target.result;
        };

        reader.onerror = (evt) => {
          console.error(evt);
        };
      }
      // this.image_url = URL.createObjectURL(this.file);
    },
    async save() {
      try {
        this.isLoading = true;
        // await axios
        //   .post("/qualities", this.form)
        let formTest = {};
        Object.assign(formTest, this.form);
        let formData = this.jsonToFormData(formTest);
        if (
          this.$refs.design_paper_file.files &&
          this.$refs.design_paper_file.files[0]
        ) {
          let attachment_1 = this.$refs.design_paper_file.files[0];
          formData.append("design_paper_imagepath", attachment_1);
        }
        if (this.$refs.fabric_file.files && this.$refs.fabric_file.files[0]) {
          let attachment_2 = this.$refs.fabric_file.files[0];
          formData.append("fabric_imagepath", attachment_2);
        }
        await axios
          .post(`/qualities`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(async () => {
            await this.saveUserTimestamp(null, this.form);
            this.isLoading = false;
            this.$router.push("/qualities");
          })
          .catch((error) => {
            // redirect to Home Page
            if (error.response.status != 422) {
              // window.location.href = "/auth/login";
            }
            console.error("Error fetching data:", error);
          });
      } catch (e) {
        // alert("error");
        console.log(e);
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    jsonToFormData(data) {
      const formData = new FormData();
      this.buildFormData(formData, data);
      return formData;
    },
    buildFormData(formData, data, parentKey) {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;
        formData.append(parentKey, value);
      }
    },
  },
};
</script>
