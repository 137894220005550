<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title>
          <v-btn
            class="mr-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Add a User
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.first_name"
                outlined
                dense
                label="First Name *"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.first_name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.middle_name"
                outlined
                dense
                label="Middle Name"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.middle_name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.last_name"
                outlined
                dense
                label="Last Name *"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.last_name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.email"
                outlined
                dense
                label="Email *"
                prepend-inner-icon="mdi-email"
                :error-messages="errors.email"
                ></v-text-field>
              </v-col>
              <v-col sm="4">
                <v-text-field
                v-model="form.phone"
                outlined
                dense
                label="Phone"
                prepend-inner-icon="mdi-phone"
                :error-messages="errors.phone"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.token_no"
                outlined
                dense
                label="Token No"
                prepend-inner-icon="mdi-alphabet-cyrillic"
                :error-messages="errors.token_no"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="country"
                outlined
                dense
                v-model="form.country_id"
                :items="countryItems"
                label="Country"
                prepend-inner-icon="mdi-earth"
                clearable
                @input="getCountryWiseState()"
                :error-messages="errors.country_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="state"
                :disabled="!form.country_id"
                outlined
                dense
                v-model="form.state_id"
                :items="stateItems"
                label="State"
                prepend-inner-icon="mdi-earth"
                clearable
                @input="getStateWiseCity()"
                :error-messages="errors.state_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="city"
                :disabled="!form.state_id"
                outlined
                dense
                v-model="form.city_id"
                :items="cityItems"
                label="City"
                prepend-inner-icon="mdi-earth"
                clearable
                :error-messages="errors.city_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="12">
              <v-textarea
                outlined
                rows="2"
                v-model="form.address"
                label="Address"
              ></v-textarea>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="employee_type"
                outlined
                dense
                v-model="form.employee_type"
                :items="employeeTypeItems"
                label="Employee Type"
                prepend-inner-icon="mdi-earth"
                clearable
                :error-messages="errors.employee_type_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="position"
                outlined
                dense
                v-model="form.position_id"
                :items="positionItems"
                label="Position *"
                prepend-inner-icon="mdi-account-tie-outline"
                clearable
                :error-messages="errors.position_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.basic"
                outlined
                dense
                label="Basic"
                prepend-inner-icon="mdi-hand-coin-outline"
                append-icon="mdi-currency-inr"
                :error-messages="errors.basic"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-switch
                class="mt-0 ml-5"
                v-model="form.is_esi_applicable"
                inset
                :label="`Is ESI Applicable: ${
                  form.is_esi_applicable ? 'YES' : 'NO'
                }`"
              ></v-switch>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="work_location"
                outlined
                dense
                v-model="form.work_location_id"
                :items="workLocationItems"
                label="Work Location *"
                prepend-inner-icon="mdi-map-marker"
                clearable
                :error-messages="errors.work_location_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.bank_name"
                outlined
                dense
                label="Bank Name"
                prepend-inner-icon="mdi-bank"
                :error-messages="errors.bank_name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.account_number"
                outlined
                dense
                label="Account Number"
                prepend-inner-icon="mdi-numeric"
                :error-messages="errors.account_number"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.ifsc_code"
                outlined
                dense
                label="IFSC Code"
                prepend-inner-icon="mdi-bank"
                :error-messages="errors.ifsc_code"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.micr_number"
                outlined
                dense
                label="MICR Number"
                prepend-inner-icon="mdi-bank"
                :error-messages="errors.micr_number"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.pt"
                outlined
                dense
                label="PT"
                prepend-inner-icon="mdi-bank"
                append-icon="mdi-currency-inr"
                :error-messages="errors.pt"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.pf_no"
                outlined
                dense
                label="PF Number"
                prepend-inner-icon="mdi-bank"
                :error-messages="errors.pf_no"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.uan_no"
                outlined
                dense
                label="UAN Number"
                prepend-inner-icon="mdi-bank"
                :error-messages="errors.uan_no"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-switch
                class="mt-0 ml-5"
                v-model="form.is_active"
                inset
                :label="`Is Active: ${form.is_active ? 'YES' : 'NO'}`"
              ></v-switch>
            </v-col>
          </v-row>
          <v-divider class="mt-5 mb-5"> </v-divider>
          <v-btn
            :loading="isLoading"
            :disabled="isLoading"
            rounded
            color="primary"
            dark
            @click="save"
          >
            Save User
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "UserCreatePage",
  data() {
    return {
      form: {
        is_active: true,
        soft_password: "123456",
      },
      companyId: "",
      positionItems: [],
      workLocationItems: [],
      countryItems: [],
      stateItems: [],
      allstateItems: [],
      cityItems: [],
      allcityItems: [],
      isLoading: false,
      employeeTypeItems: [
        { id: "Piece Rate Employee", text: "Piece Rate Employee", value: 1 },
        { id: "Time Rate Employee", text: "Time Rate Employee", value: 2 },
      ],
    };
  },
  mounted() {
    this.form.company_id = this.company.id;
    this.form.role_id = "3";
    this.getMasters();
  },
  methods: {
    async getMasters() {
      await axios
        .get(`users/masters`)
        .then((masters) => {
          masters = masters.data;
          //Positions
          masters.positions.forEach((p) => {
            this.positionItems.push({
              id: p.id,
              text: p.name,
              value: p.id,
            });
          });
          //Countries
          masters.work_locations.forEach((wL) => {
            this.workLocationItems.push({
              id: wL.id,
              text: wL.description,
              value: wL.id,
            });
          });
          //Countries
          masters.countries.forEach((country) => {
            this.countryItems.push({
              id: country.id,
              text: country.description,
              value: country.id,
            });
          });
          //State
          masters.states.forEach((state) => {
            this.stateItems.push({
              id: state.id,
              text: state.name,
              value: state.id,
            });
            this.allstateItems.push({
              id: state.id,
              text: state.name,
              value: state.id,
              country_id: state.country_id,
            });
          });
          //City
          masters.cities.forEach((city) => {
            this.cityItems.push({
              id: city.id,
              text: city.name,
              value: city.id,
            });
            this.allcityItems.push({
              id: city.id,
              text: city.name,
              value: city.id,
              state_id: city.state_id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          window.location.href = "/auth/login";
        });
    },
    getCountryWiseState() {
      this.form.state_id = "";
      this.stateItems = [];
      this.stateItems = this.allstateItems.filter((item) => {
        return item.country_id == this.form.country_id;
      });
    },
    getStateWiseCity() {
      this.form.city_id = "";
      this.cityItems = [];
      this.cityItems = this.allcityItems.filter((item) => {
        return item.state_id == this.form.state_id;
      });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/users", this.form);
        this.isLoading = false;
        await this.saveUserTimestamp(null, this.form);
        this.$router.push("/users");
      } catch (e) {
        // alert("error");
        this.isLoading = false;
      }
    },
  },
};
</script>
