<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn
            class="mr-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Add Stock Type
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.name"
                outlined
                label="Name *"
                :error-messages="errors.name"
                prepend-inner-icon="mdi-basket-unfill"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.code"
                outlined
                label="Code *"
                prepend-inner-icon="mdi-basket-unfill"
                :error-messages="errors.code"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-switch
                class="mt-0 ml-5"
                v-model="form.is_active"
                inset
                :label="`Is Active: ${form.is_active ? 'YES' : 'NO'}`"
              ></v-switch>
            </v-col>
            <v-col sm="4">
              <v-switch
                class="mt-0 ml-5"
                v-model="form.is_consider_for_inventory"
                inset
                :label="`Is Consider for inventory: ${
                  form.is_consider_for_inventory ? 'YES' : 'NO'
                }`"
              ></v-switch>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <v-btn
            rounded
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="save"
          >
            Save Stock Type
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "StockTypeCreatePage",
  data() {
    return {
      form: {
        is_active: true,
        is_consider_for_inventory: true,
      },
      isLoading: false,
    };
  },
  methods: {
    async save() {
      try {
        this.isLoading = true;
        await axios
          .post("/stock_types", this.form)
          .then(async () => {
            await this.saveUserTimestamp(null, this.form);
            this.isLoading = false;
            this.$router.push("/stock-types");
          })
          .catch((error) => {
            // redirect to Home Page
            if (error.response.status != 422) {
              window.location.href = "/auth/login";
            }
            console.error("Error fetching data:", error);
          });
      } catch (e) {
        // alert("error");
        console.log(e);
        this.isLoading = false;
      }
      this.isLoading = false;
    },
  },
};
</script>
