<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn
            class="mr-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Edit Gst
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete
                ref="gstType"
                outlined
                v-model="form.gst_type"
                :items="gstTypeItems"
                label="GST Type"
                prepend-inner-icon="mdi-earth"
                clearable
                :error-messages="errors.gst_type"
              ></v-autocomplete>
            </v-col>

            <template v-if="form.gst_type == 1">
              <v-col sm="4">
                <v-text-field
                  v-model="form.igst_percent"
                  outlined
                  label="IGST Percent"
                  :error-messages="errors.igst_percent"
                  prepend-inner-icon="mdi-percent"
                ></v-text-field>
              </v-col>
            </template>
            <template v-else>
              <v-col sm="4">
                <v-text-field
                  v-model="form.cgst_percent"
                  outlined
                  label="CGST Percent"
                  prepend-inner-icon="mdi-percent"
                  :error-messages="errors.cgst_percent"
                ></v-text-field>
              </v-col>
              <v-col sm="4">
                <v-text-field
                  v-model="form.sgst_percent"
                  outlined
                  label="SGST Percent"
                  prepend-inner-icon="mdi-percent"
                  :error-messages="errors.sgst_percent"
                ></v-text-field>
              </v-col>
            </template>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <v-btn
            rounded
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="save"
          >
            Update GST
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";

export default {
  name: "GstEditPage",
  data() {
    return {
      form: {
        is_active: false,
      },
      gstTypeItems: [
        { id: "IGST", text: "IGST", value: 1 },
        { id: "CGST/SGST", text: "CGST/SGST", value: 2 },
      ],
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async save() {
      try {
        this.isLoading = true;
        await axios
          .patch(`/gsts/${this.$route.params.gstId}`, this.form)
          .then(async () => {
            await this.saveUserTimestamp(null, this.form);
            this.isLoading = false;
            this.$router.push("/gsts");
          })
          .catch((error) => {
            // redirect to Home Page
            window.code.href = "/auth/login";
            console.error("Error fetching data:", error);
          });
      } catch (e) {
        // alert("error");
        console.log(e);
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async getData() {
      this.isLoading = true;
      let encryptedId = this.$route.params.gstId;
      axios
        .get(`/gsts/${encryptedId}`)
        .then((response) => {
          this.form = response.data.data;
          this.old_json = response.data.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      this.isLoading = false;
    },
  },
};
</script>
