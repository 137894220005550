var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{attrs:{"elevation":"6","rounded":"xxl"}},[_c('v-card-title',[_c('h3',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(
                `List of ${
                  _vm.currentPage == 'ValueLists' ? 'Value' : _vm.currentPage
                }`
              )}}),_c('v-btn',{staticClass:"mx-2 mb-1",attrs:{"loading":_vm.isSaving,"disabled":_vm.isSaving,"rounded":"","color":"primary"},on:{"click":_vm.save}},[_vm._v(" Save ")])],1),(this.currentPage == 'ValueLists')?_c('v-card-text',[_c('v-autocomplete',{ref:"value",attrs:{"items":_vm.valueItems,"label":"Select Value","prepend-inner-icon":"mdi-format-list-text","solo":""},on:{"change":_vm.getData},model:{value:(_vm.value_id),callback:function ($$v) {_vm.value_id=$$v},expression:"value_id"}})],1):_vm._e()],1)],1)],1),_c('v-row',[(_vm.value_id)?_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{attrs:{"elevation":"6","rounded":"xxl"}},[_c('v-card-text',[(_vm.isLoading)?_c('section',{staticStyle:{"height":"285px"}},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('v-simple-table',{attrs:{"fixed-header":"","height":`${_vm.valueLists.length ? '370px' : ''}`},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Sr No")]),_c('th',{staticClass:"text-left"},[_vm._v("Description")]),_c('th',{staticClass:"text-left"},[_vm._v("Code")]),_c('th',{staticClass:"text-right action_th"},[_vm._v("Action")])])]),_c('tbody',_vm._l((_vm.valueLists),function(valueList,i){return _c('tr',{key:`valueList${i}`},[_c('td',[_vm._v(_vm._s(i + 1))]),_c('td',[_c('v-col',{staticClass:"px-0"},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Description","input":(valueList.description =
                              valueList.description.toUpperCase()),"hide-details":"auto","error-messages":valueList.description == ''
                              ? 'this description field is required'
                              : ''},model:{value:(valueList.description),callback:function ($$v) {_vm.$set(valueList, "description", $$v)},expression:"valueList.description"}})],1)],1),_c('td',[_c('v-col',{staticClass:"px-0"},[_c('v-text-field',{attrs:{"error-messages":valueList.code == ''
                              ? 'this code field is required'
                              : '',"input":(valueList.code = valueList.code.toUpperCase()),"outlined":"","dense":"","label":"Code","hide-details":"auto"},model:{value:(valueList.code),callback:function ($$v) {_vm.$set(valueList, "code", $$v)},expression:"valueList.code"}})],1)],1),_c('td',{staticClass:"text-right"},[_c('div',{staticClass:"text-center"},[(!valueList.id)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"primary","icon":""},on:{"click":function($event){return _vm.deleteValueList(valueList)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"primary","icon":""},on:{"click":function($event){return _vm.delete_data(valueList.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)])])}),0)]},proxy:true}],null,false,1192361676)}),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"my-2",attrs:{"outlined":"","block":"","color":"primary"},on:{"click":function($event){return _vm.addEmptyValueList()}}},[_vm._v(" Add New Row ")])],1)],1)],1)],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }