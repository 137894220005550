<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn
            class="mr-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Edit Payment Terms
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.name"
                outlined
                label="Name *"
                prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.no_of_days"
                outlined
                label="No Of Days *"
                prepend-inner-icon="mdi-calendar-range"
                :error-messages="errors.no_of_days"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="term_for"
                outlined
                v-model="form.term_for"
                :items="termForItems"
                label="Term For *"
                prepend-inner-icon="mdi-earth"
                clearable
                :error-messages="errors.term_for"
              ></v-autocomplete>
            </v-col>
            <v-col sm="12">
              <span class="red--text">{{ errors.description }}</span>
              <vue-editor
                v-model="form.description"
                placeholder="Description"
              ></vue-editor>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.advance_percentage"
                outlined
                label="Advance Percentage"
                prepend-inner-icon="mdi-percent"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-switch
                class="mt-0 ml-5"
                v-model="form.is_display_by_default"
                inset
                :label="`Display by default: ${
                  form.is_display_by_default ? 'YES' : 'NO'
                }`"
              ></v-switch>
            </v-col>
            <v-col sm="4">
              <v-switch
                class="mt-0 ml-5"
                v-model="form.is_active"
                inset
                :label="`Is Active: ${form.is_active ? 'YES' : 'NO'}`"
              ></v-switch>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <v-btn
            rounded
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="save"
          >
            Update Payment Terms
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";

export default {
  name: "PaymentTermEditPage",
  data() {
    return {
      form: {},
      termForItems: [
        { id: "EXPORT", text: "EXPORT", value: 1 },
        { id: "DOMESTIC", text: "DOMESTIC", value: 2 },
      ],
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let encryptedId = this.$route.params.paymentTermId;
      axios
        .get(`/payment_terms/${encryptedId}`)
        .then((response) => {
          this.form = response.data.data;
          this.old_json = response.data.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        await axios
          .patch(
            `/payment_terms/${this.$route.params.paymentTermId}`,
            this.form
          )
          .then(async () => {
            await this.saveUserTimestamp(null, this.form);
            this.isLoading = false;
            this.$router.push("/payment-terms");
          })
          .catch((error) => {
            // redirect to Home Page
            window.code.href = "/auth/login";
            console.error("Error fetching data:", error);
          });
      } catch (e) {
        // alert("error");
        console.log(e);
        this.isLoading = false;
      }
  this.isLoading = false;
    },
  },
};
</script>
