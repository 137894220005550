<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn
            class="mr-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Edit Account Group
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete
                ref="account_type_id"
                outlined
                v-model="form.account_type_id"
                :items="accountTypeItems"
                label="Account Type *"
                prepend-inner-icon="mdi-earth"
                clearable
                @input="getTypeWiseSubType()"
                :error-messages="errors.account_type_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="Account Sub Type"
                outlined
                :loading="isSubTypeLoading"
                :disabled="!form.account_type_id"
                v-model="form.account_sub_type_id"
                :items="accountSubTypeItems"
                label="Account Sub Type *"
                prepend-inner-icon="mdi-earth"
                :error-messages="errors.account_sub_type_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.name"
                outlined
                label="Name *"
                prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-switch
                class="mt-0 ml-5"
                v-model="form.is_active"
                inset
                :label="`Is Active: ${form.is_active ? 'YES' : 'NO'}`"
              ></v-switch>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <v-btn
            rounded
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="save"
          >
            Update Account Group
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";

export default {
  name: "accountGroupEditPage",
  data() {
    return {
      form: {},
      accountTypeItems: [],
      accountSubTypeItems: [],
      allaccountSubTypeItems: [],
      isLoading: false,
      isSubTypeLoading: false,
    };
  },
  mounted() {
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      await axios
        .get(`account_groups/masters`)
        .then((masters) => {
          masters = masters.data;
          //Account Types
          masters.account_types.forEach((aT) => {
            this.accountTypeItems.push({
              id: aT.id,
              text: aT.description,
              value: aT.id,
            });
          });
          //Account Sub Types
          masters.account_sub_types.forEach((aST) => {
            this.accountSubTypeItems.push({
              id: aST.id,
              text: aST.name,
              value: aST.id,
            });
            this.allaccountSubTypeItems.push({
              id: aST.id,
              text: aST.name,
              value: aST.id,
              account_type_id: aST.account_type_id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          // window.location.href = "/auth/login";
        });
    },
    getTypeWiseSubType() {
      this.isSubTypeLoading = true;
      this.form.account_sub_type_id = "";
      this.accountSubTypeItems = [];
      this.accountSubTypeItems = this.allaccountSubTypeItems.filter((item) => {
        return item.account_type_id == this.form.account_type_id;
      });
      this.isSubTypeLoading = false;
    },
    async getData() {
      this.isLoading = true;
      let encryptedId = this.$route.params.accountGroupId;
      axios
        .get(`/account_groups/${encryptedId}`)
        .then((response) => {
          this.form = response.data.data;
          this.old_json = response.data.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        await axios
          .patch(
            `/account_groups/${this.$route.params.accountGroupId}`,
            this.form
          )
          .then(async () => {
            await this.saveUserTimestamp(null, this.form);
            this.isLoading = false;
            this.$router.push("/account-groups");
          })
          .catch((error) => {
            // redirect to Home Page
            window.code.href = "/auth/login";
            console.error("Error fetching data:", error);
          });
      } catch (e) {
        // alert("error");
        console.log(e);
        this.isLoading = false;
      }
  this.isLoading = false;
    },
  },
};
</script>
