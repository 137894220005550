<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn
            class="mr-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Add Delivery Terms
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.name"
                outlined
                label="Name"
                prepend-inner-icon="mdi-card-text-outline"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="term_for"
                outlined
                v-model="form.term_for"
                :items="termForItems"
                label="Term For"
                prepend-inner-icon="mdi-earth"
                clearable
                :error-messages="errors.term_for"
              ></v-autocomplete>
            </v-col>
            <v-col sm="12">
              <span class="red--text">{{ errors.description }}</span>
              <vue-editor v-model="form.description" placeholder="Description"></vue-editor>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <v-btn
            rounded
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="save"
          >
            Save Delivery Terms
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "DeliveryTermsCreatePage",
  data() {
    return {
      form: {
        name: "",
      },
      termForItems: [
        { id: "EXPORT", text: "EXPORT", value: "1" },
        { id: "DOMESTIC", text: "DOMESTIC", value: "2" },
      ],
      isLoading: false,
    };
  },
  methods: {
    async save() {
      try {
        this.isLoading = true;
        await axios
          .post("/delivery_terms", this.form)
          .then(async () => {
            await this.saveUserTimestamp(null, this.form);
            this.isLoading = false;
            this.$router.push("/delivery-terms");
          })
          .catch((error) => {
            // redirect to Home Page
            window.location.href = "/auth/login";
            console.error("Error fetching data:", error);
          });
      } catch (e) {
        // alert("error");
        console.log(e);
        this.isLoading = false;
      }
  this.isLoading = false;
    },
  },
};
</script>
