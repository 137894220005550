<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn
            class="mr-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Add Module
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.name"
                outlined
                label="Name"
                prepend-inner-icon="mdi-card-text-outline"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <v-btn
            rounded
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="save"
          >
            Save Module
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "ModuleCreatePage",
  data() {
    return {
      form: {
        role_id: 3,
        name: "",
      },
      isLoading: false,
    };
  },
  methods: {
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/modules", this.form);
        await this.saveUserTimestamp(null, this.form);
        this.isLoading = false;
        this.$router.push("/modules");
      } catch (e) {
        // alert("error");
        console.log(e);
        this.isLoading = false;
      }
  this.isLoading = false;
    },
  },
};
</script>
