<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-title>
              <h3
                class="font-weight-bold"
                v-text="
                  `List of ${
                    currentPage == 'ValueLists' ? 'Value' : currentPage
                  }`
                "
              ></h3>
              <v-btn
                class="mx-2 mb-1"
                :loading="isSaving"
                :disabled="isSaving"
                rounded
                color="primary"
                @click="save"
              >
                Save
              </v-btn>
              <!-- <v-btn>
            <download-excel
              :title="title"
              :data="value_list_excel"
              :fields="excel_fields"
              worksheet="Value_lists"
              name="Value_list.xls"
            >
              Export to Excel
            </download-excel></v-btn
          > -->
            </v-card-title>
            <v-card-text v-if="this.currentPage == 'ValueLists'">
              <v-autocomplete
                @change="getData"
                ref="value"
                v-model="value_id"
                :items="valueItems"
                label="Select Value"
                prepend-inner-icon="mdi-format-list-text"
                solo
              ></v-autocomplete>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12" v-if="value_id">
          <v-card elevation="6" rounded="xxl">
            <v-card-text>
              <section v-if="isLoading" style="height: 285px">
                <v-progress-linear
                  indeterminate
                  color="primary"
                ></v-progress-linear>
              </section>
              <v-simple-table
                v-else
                fixed-header
                :height="`${valueLists.length ? '370px' : ''}`"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Sr No</th>
                      <th class="text-left">Description</th>
                      <th class="text-left">Code</th>
                      <th class="text-right action_th">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(valueList, i) in valueLists"
                      :key="`valueList${i}`"
                    >
                      <td>{{ i + 1 }}</td>
                      <td>
                        <v-col class="px-0">
                          <v-text-field
                            v-model="valueList.description"
                            outlined
                            dense
                            label="Description"
                            :input="
                              (valueList.description =
                                valueList.description.toUpperCase())
                            "
                            hide-details="auto"
                            :error-messages="
                              valueList.description == ''
                                ? 'this description field is required'
                                : ''
                            "
                          ></v-text-field>
                        </v-col>
                      </td>
                      <td>
                        <v-col class="px-0">
                          <v-text-field
                            :error-messages="
                              valueList.code == ''
                                ? 'this code field is required'
                                : ''
                            "
                            v-model="valueList.code"
                            :input="
                              (valueList.code = valueList.code.toUpperCase())
                            "
                            outlined
                            dense
                            label="Code"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>
                      </td>

                      <td class="text-right">
                        <div class="text-center">
                          <v-tooltip bottom v-if="!valueList.id">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                small
                                color="primary"
                                icon
                                @click="deleteValueList(valueList)"
                              >
                                <v-icon>mdi-delete-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                          <v-tooltip bottom v-else>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                small
                                color="primary"
                                icon
                                @click="delete_data(valueList.id)"
                              >
                                <v-icon>mdi-delete-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-row>
                <v-col>
                  <v-btn
                    class="my-2"
                    outlined
                    block
                    color="primary"
                    @click="addEmptyValueList()"
                  >
                    Add New Row
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";

export default {
  name: "ValueListsPage",
  data() {
    return {
      value_id: "",
      valueItems: [],
      valueLists: [],
      excel_fields: {
        "Sr No": "sr_no",
        DESCRIPTION: "description",
        CODE: "code",
      },
      title: "",
      value_list_excel: [],
      isLoading: false,
      isSaving: false,
      currentPage: "",
    };
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        this.currentPage = this.$route.name;
        this.getMasters();
      }
    },
  },
  mounted() {
    this.currentPage = this.$route.name;
    this.getMasters();
  },
  methods: {
    async getMasters() {
      this.valueItems = [];
      let masters = await axios.get("value_lists/masters");
      masters = masters.data;
      masters.values.forEach((value) => {
        this.valueItems.push({
          id: value.id,
          text: value.name,
          value: value.id,
        });
      });
      if (this.currentPage != "ValueLists") {
        this.value_id = this.valueItems.find((item) => {
          return item.text.toLowerCase() == this.currentPage.toLowerCase();
        }).id;
        this.getData();
      }
    },
    async getData() {
      this.isLoading = true;
      let valueLists = await axios.get(`/values/${this.value_id}/value_lists`);
      this.valueLists = valueLists.data.data;
      this.count = valueLists.data.count;
      this.isLoading = false;
      this.exportToExcel();
    },
    exportToExcel() {
      let value_name = this.valueItems.find(
        (valueItem) => valueItem.id === this.value_id
      );
      this.title = "List of " + value_name.text;
      this.value_list_excel = [];
      let sr_no = 1;
      this.valueLists.forEach((valuelist) => {
        this.value_list_excel.push({
          sr_no: sr_no,
          description: valuelist.description,
          code: valuelist.code,
        });
        sr_no++;
      });
    },
    searchData() {
      this.getData();
    },
    addEmptyValueList() {
      this.valueLists.push({
        value_id: this.value_id,
        company_id: this.company.id,
        description: "",
        code: "",
        is_active: 1,
      });
    },
    async save() {
      if (this.valueLists.length > 0) {
        this.isSaving = true;
        let payload = {
          datas: this.valueLists,
        };

        let response = await axios.post(
          `/values/${this.value_id}/value_lists_multiple`,
          payload
        );
        this.valueLists = response.data.data;
        this.isSaving = false;
      }
    },
    deleteValueList(valueList, index) {
      // Check if both description and code are empty
      if (!valueList.description.trim() && !valueList.code.trim()) {
        // If both are empty, simply remove the row from the valueLists array
        this.valueLists.splice(index, 1);
      } else {
        // If at least one field is not empty, proceed with the API call
        this.delete_data(valueList.id, index);
      }
    },
    async delete_data(id) {
      let check = confirm("Are you sure you want to delete the data");
      if (check) {
        try {
          this.isLoading = true;
          await axios.post(`value_lists/delete/${this.encryptIt(id)}`);
          this.isLoading = false;
          this.getData();
        } catch (e) {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
