<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-title>
              <h3 class="font-weight-bold" v-text="`${CurrentFabricType}`"></h3>
              <v-btn
                class="mx-2 mb-2"
                fab
                x-small
                color="primary"
                :to="`${
                  CurrentFabricType == 'FABRIC SO'
                    ? '/fabric-sos/create'
                    : '/fabric-pos/create'
                }`"
              >
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field
                    solo
                    rounded
                    v-model="filter.search_keyword"
                    label="Search ... "
                    color="#e60040"
                    prepend-inner-icon="mdi-magnify"
                    clearable
                    @click:clear="
                      (filter.search_keyword = null), (page = 1), getData()
                    "
                    @keydown.enter="(page = 1), getData()"
                  >
                  </v-text-field>
                </v-col>
                <template v-if="CurrentFabricType == 'FABRIC SO'">
                  <v-col sm="3" v-if="positionName != 'BUYER'">
                    <v-autocomplete
                      ref="buyer"
                      solo
                      rounded
                      v-model="filter.buyer_id"
                      :items="buyerItems"
                      clearable
                      @click:clear="(filter.buyer_id = null), (page = 1)"
                      label="Buyer"
                      @input="getData"
                      prepend-inner-icon="mdi-earth"
                    ></v-autocomplete>
                  </v-col>
                </template>
                <template v-else>
                  <v-col sm="3" v-if="positionName != 'VENDOR'">
                    <v-autocomplete
                      ref="vendor"
                      solo
                      rounded
                      v-model="filter.vendor_id"
                      :items="vendorItems"
                      clearable
                      @click:clear="(filter.vendor_id = null), (page = 1)"
                      label="Vendor"
                      @input="getData"
                      prepend-inner-icon="mdi-earth"
                    ></v-autocomplete>
                  </v-col>
                </template>
                <v-col sm="3">
                  <v-autocomplete
                    ref="Agent"
                    solo
                    rounded
                    v-model="filter.agent_id"
                    :items="agentItems"
                    clearable
                    @click:clear="(filter.agent_id = null), (page = 1)"
                    label="Agent"
                    @input="getData"
                    prepend-inner-icon="mdi-earth"
                  ></v-autocomplete>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete
                    ref="Quality"
                    solo
                    rounded
                    v-model="filter.quality"
                    :items="qualityItems"
                    clearable
                    @click:clear="(filter.quality = null), (page = 1)"
                    label="Quality"
                    @input="getData"
                    prepend-inner-icon="mdi-toggle-switch"
                  ></v-autocomplete>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete
                    ref="status"
                    solo
                    rounded
                    v-model="filter.is_active"
                    :items="statuses"
                    clearable
                    @click:clear="(filter.is_active = null), (page = 1)"
                    label="Status"
                    @input="getData"
                    prepend-inner-icon="mdi-toggle-switch"
                  ></v-autocomplete>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete
                    ref="Approver_status"
                    solo
                    rounded
                    v-model="filter.approver_status"
                    :items="approverStatuses"
                    clearable
                    @click:clear="(filter.approver_status = null), (page = 1)"
                    label="Approver Status"
                    @input="getData"
                    prepend-inner-icon="mdi-toggle-switch"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-text>
              <section v-if="isLoading" style="height: 285px">
                <v-progress-linear
                  indeterminate
                  color="primary"
                ></v-progress-linear>
              </section>
              <v-simple-table
                v-else
                fixed-header
                height="285"
                class="striped-table"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left sr_no">Sr No</th>
                      <th class="text-left">Status</th>
                      <th class="text-left" style="min-width: none">Date</th>
                      <th class="text-left" style="min-width: none">
                        Customer SO
                      </th>
                      <template v-if="CurrentFabricType == 'FABRIC SO'">
                        <th class="text-left" style="min-width: none">Buyer</th>
                      </template>
                      <template v-else>
                        <th class="text-left" style="min-width: none">
                          Vendor
                        </th>
                      </template>
                      <th class="text-left" style="min-width: none">Agent</th>
                      <template v-if="CurrentFabricType == 'FABRIC PO'">
                        <th class="text-left" style="min-width: none">
                          Quality
                        </th>
                      </template>
                      <th class="text-left" style="min-width: none">
                        Total Quantity
                      </th>
                      <template v-if="CurrentFabricType == 'FABRIC SO'">
                        <th class="text-left" style="min-width: none">
                          Alloted
                        </th>
                        <th class="text-left" style="min-width: none">
                          Balance
                        </th>
                      </template>
                      <th class="text-left" style="min-width: none">
                        Approver Status
                      </th>
                      <th class="text-left" style="min-width: none">
                        Vendor Approval Status
                      </th>
                      <th class="text-left" style="min-width: none">
                        Delivery Flow
                      </th>
                      <th class="text-right action_th">Action</th>
                    </tr>
                  </thead>
                  <tbody v-if="fabric_pos.length != 0">
                    <tr
                      v-for="(fabric_po, i) in fabric_pos"
                      :key="`fabric_po_${i}`"
                      :class="{
                        'table-row-even': i % 2 === 0,
                        'table-row-odd': i % 2 !== 0,
                        'table-row-last': fabric_pos.length - 1 == i,
                      }"
                    >
                      <td style="min-width: 85px">
                        {{ i + 1 }}
                      </td>
                      <td>
                        <v-tooltip bottom v-if="fabric_po.is_active == 1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="teal"
                              text-color="white"
                            >
                              <v-avatar left>
                                <v-icon size="15"
                                  >mdi-checkbox-marked-circle</v-icon
                                >
                              </v-avatar>
                              Active
                            </v-chip>
                          </template>
                          <span>{{ CurrentFabricType }} is Active</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="fabric_po.is_active == 0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="red"
                              text-color="white"
                            >
                              <v-avatar left>
                                <v-icon size="15">mdi-close-circle</v-icon>
                              </v-avatar>
                              Inactive
                            </v-chip>
                          </template>
                          <span>{{ CurrentFabricType }} is InActive</span>
                        </v-tooltip>
                      </td>
                      <td>{{ fabric_po.date || "" }}</td>
                      <td>{{ fabric_po.customer_so || "" }}</td>
                      <template v-if="CurrentFabricType == 'FABRIC SO'">
                        <td>
                          {{
                            fabric_po.buyer ? fabric_po.buyer.name : "" || ""
                          }}
                        </td>
                      </template>
                      <template v-else>
                        <td>
                          {{
                            fabric_po.vendor ? fabric_po.vendor.name : "" || ""
                          }}
                        </td>
                      </template>

                      <td>
                        {{ fabric_po.agent ? fabric_po.agent.name : "" || "" }}
                      </td>
                      <template v-if="CurrentFabricType == 'FABRIC PO'">
                        <td>
                          {{
                            fabric_po.fabric_po_items[0].quality
                              .quality_description || ""
                          }}
                        </td>
                      </template>
                      <td>{{ fabric_po.total_quantity || "" }}</td>
                      <template v-if="CurrentFabricType == 'FABRIC SO'">
                        <td>
                          {{ fabric_po.total_dispatched || "" }}
                        </td>
                        <td>
                          {{ fabric_po.pending_meters || "" }}
                        </td>
                      </template>
                      <td>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              class="ma-2"
                              small
                              v-bind="attrs"
                              v-on="on"
                              v-if="fabric_po.approver_status == 0"
                              id="Pending for Approval"
                              color="yellow"
                            >
                              Pending
                            </v-chip>
                          </template>
                          <span>Pending for Approval</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              class="ma-2"
                              small
                              dark
                              v-bind="attrs"
                              v-on="on"
                              v-if="fabric_po.approver_status == true"
                              id="Approved"
                              color="green"
                            >
                              Approved
                            </v-chip>
                          </template>
                          <span>Approved</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              class="ma-2"
                              small
                              dark
                              v-bind="attrs"
                              v-on="on"
                              color="red"
                              id="Rejected"
                              v-if="fabric_po.approver_status == 2"
                            >
                              Rejected
                            </v-chip>
                          </template>
                        </v-tooltip>
                      </td>
                      <td>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              class="ma-2"
                              small
                              v-bind="attrs"
                              v-on="on"
                              v-if="fabric_po.vendor_approval_status == 0"
                              id="Pending for Approval"
                              color="yellow"
                            >
                              Pending
                            </v-chip>
                          </template>
                          <span
                            >Pending for
                            {{ positionName == "VENDOR" ? "your" : "Vendor" }}
                            Approval</span
                          >
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              class="ma-2"
                              small
                              dark
                              v-bind="attrs"
                              v-on="on"
                              v-if="fabric_po.vendor_approval_status == true"
                              id="Approved"
                              color="green"
                            >
                              Approved
                            </v-chip>
                          </template>
                          <span>Approved</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              class="ma-2"
                              small
                              dark
                              v-bind="attrs"
                              v-on="on"
                              color="red"
                              id="Rejected"
                              v-if="fabric_po.vendor_approval_status == 2"
                            >
                              Rejected
                            </v-chip>
                          </template>
                        </v-tooltip>
                      </td>
                      <td>
                        <v-tooltip
                          bottom
                          v-if="fabric_po.delivery_flow_filepath"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              :href="
                                mediaUrl + fabric_po.delivery_flow_filepath
                              "
                              color="primary"
                              small
                              target="_blank"
                            >
                              View
                            </v-btn>
                          </template>
                          <span> View Delivery Flow</span>
                        </v-tooltip>
                      </td>
                      <td class="text-right">
                        <v-tooltip bottom v-if="roleName == 'ADMIN'">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              fab
                              x-small
                              class="my-auto"
                              color="error"
                              @click="Delete_this(fabric_po.id)"
                            >
                              <v-icon dark> mdi-delete </v-icon>
                            </v-btn>
                          </template>
                          <span>Delete this {{ CurrentFabricType }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              class="ml-2"
                              fab
                              x-small
                              color="primary"
                              :to="`${
                                CurrentFabricType == 'FABRIC SO'
                                  ? '/fabric-sos/' +
                                    encryptIt(fabric_po.id) +
                                    '/fabric-so-delivery-logs'
                                  : '/fabric-pos/' +
                                    encryptIt(fabric_po.id) +
                                    '/fabric-po-delivery-logs'
                              }`"
                            >
                              <v-icon dark> mdi-eye </v-icon>
                            </v-btn>
                          </template>
                          <span>View Delivery Logs</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              class="ml-2"
                              fab
                              x-small
                              color="primary"
                              :to="`${
                                CurrentFabricType == 'FABRIC SO'
                                  ? '/fabric-sos/' +
                                    encryptIt(fabric_po.id) +
                                    '/fabric-so-dispatches'
                                  : '/fabric-pos/' +
                                    encryptIt(fabric_po.id) +
                                    '/fabric-po-dispatches'
                              }`"
                            >
                              <v-icon dark> mdi-truck-check-outline </v-icon>
                            </v-btn>
                          </template>
                          <span>View Dispatch Log</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              class="ml-2"
                              fab
                              x-small
                              color="primary"
                              :to="`${
                                CurrentFabricType == 'FABRIC SO'
                                  ? '/fabric-sos/' + encryptIt(fabric_po.id)
                                  : '/fabric-pos/' + encryptIt(fabric_po.id)
                              }`"
                            >
                              <v-icon dark> mdi-pencil </v-icon>
                            </v-btn>
                          </template>
                          <span>Edit this {{ CurrentFabricType }}</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="10" class="text-center">No data found</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-pagination rounded v-model="page" :length="paginationLength">
              </v-pagination>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Clear Success Dialog Box -->
      <v-dialog v-model="is_deleted_fabric_po" width="600">
        <v-card rounded="xxl">
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: green; font-size: 90px; width: 200px !important"
                >
                  mdi-delete-empty
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>{{ CurrentFabricType }} deleted successfully.</h3>
                      <br />
                      <v-btn
                        color="success"
                        @click="is_deleted_fabric_po = false"
                      >
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "FabricPOsPage",
  data() {
    return {
      filter: {
        search_keyword: "",
        is_active: "",
      },
      fabric_pos: [],
      vendorGroupItems: [],
      vendorItems: [],
      buyerItems: [],
      agentItems: [],
      termConditionItems: [],
      paymentTermItems: [],
      qualityItems: [],
      deliveryTermItems: [],
      certificationTypeItems: [],
      soItems: [],
      companyTypeItems: [],
      consigneeItems: [],
      igstItems: [],
      cgstItems: [],
      sgstItems: [],
      isDeleteItemDialogs: [],
      isDeleteCondition_TypeDialogs: [],
      fabricTypeItems: [
        { id: "FINISHED", text: "FINISHED", value: 1 },
        { id: "GREY", text: "GREY", value: 2 },
      ],
      gstTypeItems: [
        { id: "IGST", text: "IGST", value: 1 },
        { id: "CGST/SGST", text: "CGST/SGST", value: 2 },
      ],
      uomItems: [
        { id: "MTR", text: "MTR", value: 1 },
        { id: "YRD", text: "YRD", value: 2 },
      ],
      statuses: [
        {
          id: 0,
          text: "Select Active Status",
          value: "",
        },
        { id: "ACTIVE", text: "ACTIVE", value: "1" },
        { id: "INACTIVE", text: "INACTIVE", value: "0" },
      ],
      approverStatuses: [
        { id: "PENDING", text: "PENDING", value: 0 },
        { id: "APPROVED", text: "APPROVED", value: 1 },
        { id: "REJECTED", text: "REJECTED", value: 2 },
      ],
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      isLoading: false,
      delete_fabric_po: false,
      is_deleted_fabric_po: false,
      CurrentFabricType: "",
    };
  },
  watch: {
    page: "getData",
    $route() {
      this.getData();
    },
  },
  mounted() {
    this.CurrentFabricType =
      this.$route.name == "FabricSoPage" ? "FABRIC SO" : "FABRIC PO";
    this.filter.vendor_id =
      this.positionName == "VENDOR" ? this.user.vendor.id : "";
    this.filter.buyer_id =
      this.positionName == "BUYER" ? this.user.buyer.id : "";
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      await axios
        .get(`fabric_pos/masters`)
        .then((masters) => {
          masters = masters.data;
          //Vendor Groups
          masters.vendor_groups.forEach((vG) => {
            this.vendorGroupItems.push({
              id: vG.id,
              text: vG.name,
              value: vG.id,
            });
          });
          //Vendors
          masters.vendors.forEach((v) => {
            this.vendorItems.push({
              id: v.id,
              text: v.name,
              value: v.id,
            });
          });
          //Buyers
          masters.buyers.forEach((v) => {
            this.buyerItems.push({
              id: v.id,
              text: v.name,
              value: v.id,
            });
          });
          //Agents
          masters.agents.forEach((agent) => {
            this.agentItems.push({
              id: agent.id,
              text: agent.name,
              value: agent.id,
            });
          });
          //Term Conditions
          masters.term_conditions.forEach((term_condition) => {
            this.termConditionItems.push({
              id: term_condition.id,
              text: term_condition.name,
              value: term_condition.id,
            });
          });
          //Payment Terms
          masters.payment_terms.forEach((payment_term) => {
            this.paymentTermItems.push({
              id: payment_term.id,
              text: payment_term.name,
              value: payment_term.id,
            });
          });
          //GST
          masters.gsts.forEach((gst) => {
            if (gst.gst_type == 1) {
              this.igstItems.push({
                id: gst.id,
                text: gst.igst_percent,
                value: gst.igst_percent,
                gst_type: gst.gst_type,
              });
            } else {
              this.cgstItems.push({
                id: gst.id,
                text: gst.cgst_percent,
                value: gst.cgst_percent,
                gst_type: gst.gst_type,
              });
              this.sgstItems.push({
                id: gst.id,
                text: gst.sgst_percent,
                value: gst.sgst_percent,
                gst_type: gst.gst_type,
              });
            }
          });
          //Qualities
          masters.qualities.forEach((quality) => {
            this.qualityItems.push({
              id: quality.id,
              text: quality.quality_description,
              value: quality.id,
            });
          });
          //Delivery Terms
          masters.delivery_terms.forEach((delivery_term) => {
            this.deliveryTermItems.push({
              id: delivery_term.id,
              text: delivery_term.name,
              value: delivery_term.id,
            });
          });
          //Certification Types
          masters.certification_types.forEach((certification_type) => {
            this.certificationTypeItems.push({
              id: certification_type.id,
              text: certification_type.description,
              value: certification_type.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          // window.location.href = "/auth/login";
        });
    },
    async getData() {
      this.isLoading = true;
      this.CurrentFabricType =
        this.$route.name == "FabricSoPage" ? "FABRIC SO" : "FABRIC PO";
      this.filter.search_keyword = this.filter.search_keyword
        ? this.filter.search_keyword
        : "";
      this.filter.is_active = this.filter.is_active
        ? this.filter.is_active
        : "";
      this.filter.approver_status = this.filter.approver_status
        ? this.filter.approver_status
        : "";
      this.filter.vendor_id = this.filter.vendor_id
        ? this.filter.vendor_id
        : "";
      this.filter.buyer_id = this.filter.buyer_id ? this.filter.buyer_id : "";
      this.filter.agent_id = this.filter.agent_id ? this.filter.agent_id : "";
      this.filter.type = this.CurrentFabricType == "FABRIC SO" ? "1" : "0";
      this.filter.quality_id = this.filter.quality_id
        ? this.filter.quality_id
        : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&is_active=" +
        this.filter.is_active +
        "&approver_status=" +
        this.filter.approver_status +
        "&vendor_id=" +
        this.filter.vendor_id +
        "&agent_id=" +
        this.filter.agent_id +
        "&quality_id=" +
        this.filter.quality_id +
        "&type=" +
        this.filter.type +
        "&search_keyword=" +
        this.filter.search_keyword;
      await axios
        .get(`fabric_pos?${query}`)
        .then((response) => {
          this.fabric_pos = response.data.data;
          this.fabric_pos.forEach((fabric_po) => {
            this.Calculate(fabric_po);
          });
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
    Calculate(fabric_po) {
      fabric_po.total_dispatched = 0;
      fabric_po.fabric_po_dispatches.forEach((dispatch) => {
        fabric_po.total_dispatched += dispatch.meters
          ? parseFloat(dispatch.meters)
          : 0;
      });
      fabric_po.pending_meters =
        parseFloat(fabric_po.total_quantity) -
        parseFloat(fabric_po.total_dispatched);
    },
    async Delete_this(id) {
      this.delete_fabric_po = true;
      await axios
        .post(`/fabric_pos/delete/${this.encryptIt(id)}`)
        .then(() => {
          this.delete_fabric_po = false;
          this.is_deleted_fabric_po = true;
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
  },
};
</script>
