<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn
            class="mr-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Add State
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete
                ref="country"
                outlined
                v-model="form.country_id"
                :items="countryItems"
                label="Country"
                prepend-inner-icon="mdi-earth"
                clearable
                :error-messages="errors.country_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.name"
                outlined
                label="Name"
                prepend-inner-icon="mdi-card-text-outline"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.code"
                outlined
                label="Code"
                prepend-inner-icon="mdi-card-text-outline"
              ></v-text-field>
            </v-col>
            
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <v-btn
            rounded
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="save"
          >
            Save State
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "PjpReferenceCreatePage",
  data() {
    return {
      form: {
        name: "",
      },
      countryItems: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      await axios
        .get(`states/masters`)
        .then((masters) => {
          masters = masters.data;
          //Users
          masters.countries.forEach((country) => {
            this.countryItems.push({
              id: country.id,
              text: country.description,
              value: country.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          // window.location.href = "/auth/login";
        });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios
          .post("/states", this.form)
          .then(async () => {
            await this.saveUserTimestamp(null, this.form);
            this.isLoading = false;
            this.$router.push("/states");
          })
          .catch((error) => {
            // redirect to Home Page
            window.location.href = "/auth/login";
            console.error("Error fetching data:", error);
          });
      } catch (e) {
        // alert("error");
        console.log(e);
        this.isLoading = false;
      }
  this.isLoading = false;
    },
  },
};
</script>
