<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-title>
              <h3 class="font-weight-bold" v-text="'User Timestamps'"></h3>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field
                    solo
                    rounded
                    v-model="filter.search_keyword"
                    label="Search ... "
                    color="#e60040"
                    prepend-inner-icon="mdi-magnify"
                    clearable
                    @click:clear="
                      (filter.search_keyword = null), (page = 1), getData()
                    "
                    @keydown.enter="(page = 1), getData()"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-text>
              <section v-if="isLoading" style="height: 285px">
                <v-progress-linear
                  indeterminate
                  color="primary"
                ></v-progress-linear>
              </section>
              <v-simple-table
                v-else
                fixed-header
                height="285"
                class="striped-table"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left sr_no">Sr No</th>
                      <th class="text-left" style="min-width: none">Name</th>
                      <th class="text-left">URL</th>
                      <th class="text-left">timespent</th>
                    </tr>
                  </thead>
                  <tbody v-if="user_timestamps.length != 0">
                    <tr
                      v-for="(user_timestamp, i) in user_timestamps"
                      :key="`user_timestamp_${i}`"
                      :class="{
                        'table-row-even': i % 2 === 0,
                        'table-row-odd': i % 2 !== 0,
                        'table-row-last': user_timestamps.length - 1 == i,
                      }"
                    >
                      <td style="min-width: 85px">
                        {{ i + 1 }}
                      </td>
                      <td>
                        {{ user_timestamp.name || "" }}
                      </td>
                      <td>
                        {{ user_timestamp.url || "" }}
                      </td>
                      <td>
                        {{ user_timestamp.timespent | secToHhMm }}
                      </td>
                      <td class="text-right"></td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="7" class="text-center">No data found</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-pagination rounded v-model="page" :length="paginationLength">
              </v-pagination>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "user_timestampsPage",
  data() {
    return {
      filter: {
        search_keyword: "",
        is_active: "",
      },
      user_timestamps: [],
      statuses: [
        {
          id: 0,
          text: "Select Active Status",
          value: "",
        },
        { id: "ACTIVE", text: "ACTIVE", value: "1" },
        { id: "INACTIVE", text: "INACTIVE", value: "0" },
      ],
      ExportUserTimestampTypes: [],
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      excel_fields: {
        "Sr No": "sr_no",
        Name: "name",
        Price: "price",
      },
      isLoading: false,
      isExcelloading: false,
      delete_user_timestamp: false,
      is_deleted_user_timestamp: false,
    };
  },
  watch: {
    page: "getData",
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      console.log("abc");
      this.isLoading = true;
      this.filter.search_keyword = this.filter.search_keyword
        ? this.filter.search_keyword
        : "";
      this.filter.is_active = this.filter.is_active
        ? this.filter.is_active
        : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&user_id=" +
        this.user.id +
        "&is_active=" +
        this.filter.is_active +
        "&search_keyword=" +
        this.filter.search_keyword;
      await axios
        .get(`user_timestamps?${query}`)
        .then((response) => {
          this.user_timestamps = response.data.data;
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
    //Generate Excel
    async generateExcel() {
      let query = "search_keyword=" + this.filter.search_keyword;
      let response = await axios.get(`user_timestamp_exports?${query}`);
      console.log(response.data.data);
      if (response) {
        window.open(`${response.data.data}`, "_blank");
      }
    },
    async Delete_this(id) {
      this.delete_user_timestamp = true;
      await axios
        .post(`/user_timestamps/delete/${this.encryptIt(id)}`)
        .then(() => {
          this.delete_user_timestamp = false;
          this.is_deleted_user_timestamp = true;
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
  },
};
</script>
