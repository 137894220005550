<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-title>
              <v-row>
                <v-col>
                  <span class="font-weight-bold h3">
                    Dispatches for {{ CurrentFabricType }}
                    <span v-if="fabric_po">
                      [ID:
                      {{ fabric_po.id }}]
                    </span>
                  </span>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete
                    ref="fabric"
                    solo
                    rounded
                    v-model="filter.fabric_po_id"
                    :items="fabricPoItems"
                    clearable
                    @click:clear="(filter.fabric_po_id = null), (page = 1)"
                    :label="CurrentFabricType"
                    @input="getData"
                    prepend-inner-icon="mdi-toggle-switch"
                  ></v-autocomplete>
                </v-col>
                <v-col align="center">
                  Next Delivery date :
                  <span>{{ next_estimated_delivery.date }}</span>
                  <br />
                  Meters :
                  <span>{{ next_estimated_delivery.meters }}</span>
                </v-col>
                <v-col align="right">
                  <span class="font-weight-bold h3">
                    Total Quantity : {{ fabric_po.total_quantity }} <br />
                    Dispatch Quantity
                    <span class="green--text"> {{ total_dispatched }}</span>
                    <hr />
                    Pending Quantity :
                    <span class="red--text">{{ pending_meters }}</span>
                  </span>
                </v-col>
              </v-row>
            </v-card-title>
            <!-- <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field
                    solo
                    rounded
                    v-model="filter.search_keyword"
                    label="Search ... "
                    color="#e60040"
                    prepend-inner-icon="mdi-magnify"
                    clearable
                    @click:clear="
                      (filter.search_keyword = null), (page = 1), getData()
                    "
                    @keydown.enter="(page = 1), getData()"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text> -->
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-text>
              <section v-if="isLoading" style="height: 285px">
                <v-progress-linear
                  indeterminate
                  color="primary"
                ></v-progress-linear>
              </section>
              <v-simple-table
                v-else
                fixed-header
                height="285"
                class="striped-table"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left sr_no">Sr No</th>
                      <th class="text-left">Status</th>
                      <th class="text-left" style="min-width: none">Date</th>
                      <th class="text-left" style="min-width: none">Meters</th>
                      <th class="text-left" style="min-width: none">Bill</th>
                      <th
                        class="text-right action_th"
                        v-if="roleName == 'ADMIN'"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="fabric_po_dispatches.length != 0">
                    <tr
                      v-for="(fabric_po_dispatch, i) in fabric_po_dispatches"
                      :key="`fabric_po_dispatch_${i}`"
                      :class="{
                        'table-row-even': i % 2 === 0,
                        'table-row-odd': i % 2 !== 0,
                        'table-row-last': fabric_po_dispatches.length - 1 == i,
                      }"
                    >
                      <td style="min-width: 85px">
                        {{ i + 1 }}
                      </td>
                      <td>
                        <v-tooltip
                          bottom
                          v-if="fabric_po_dispatch.is_active == 1"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="teal"
                              text-color="white"
                            >
                              <v-avatar left>
                                <v-icon size="15"
                                  >mdi-checkbox-marked-circle</v-icon
                                >
                              </v-avatar>
                              Active
                            </v-chip>
                          </template>
                          <span>State is Active</span>
                        </v-tooltip>
                        <v-tooltip
                          bottom
                          v-if="fabric_po_dispatch.is_active == 0"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="red"
                              text-color="white"
                            >
                              <v-avatar left>
                                <v-icon size="15">mdi-close-circle</v-icon>
                              </v-avatar>
                              Inactive
                            </v-chip>
                          </template>
                          <span>State is InActive</span>
                        </v-tooltip>
                      </td>
                      <td>
                        {{ fabric_po_dispatch.date || "" }}
                      </td>
                      <td>{{ fabric_po_dispatch.meters || "" }}</td>
                      <td>
                        <v-tooltip
                          bottom
                          v-if="fabric_po_dispatch.bill_imagepath"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              :href="
                                mediaUrl + fabric_po_dispatch.bill_imagepath
                              "
                              color="primary"
                              small
                              target="_blank"
                            >
                              View
                            </v-btn>
                          </template>
                          <span> View Bill</span>
                        </v-tooltip>
                      </td>
                      <td class="text-right" v-if="roleName == 'ADMIN'">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              class="ml-2"
                              fab
                              x-small
                              color="primary"
                              :to="`/fabric-po-dispatches/${encryptIt(
                                fabric_po_dispatch.id
                              )}`"
                            >
                              <v-icon dark> mdi-pencil </v-icon>
                            </v-btn>
                          </template>
                          <span>Edit this Dispatch</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="8" class="text-center">No data found</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-pagination rounded v-model="page" :length="paginationLength">
              </v-pagination>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Clear Success Dialog Box -->
      <v-dialog v-model="is_deleted_fabric_po_dispatch" width="600">
        <v-card rounded="xxl">
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: green; font-size: 90px; width: 200px !important"
                >
                  mdi-delete-empty
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>Dispatch Log deleted successfully.</h3>
                      <br />
                      <v-btn
                        color="success"
                        @click="is_deleted_fabric_po_dispatch = false"
                      >
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "StatesPage",
  data() {
    return {
      filter: {
        search_keyword: "",
        is_active: "",
      },
      fabric_po_dispatches: [],
      fabric_pos: [],
      fabricPoItems: [],
      next_estimated_delivery: {},
      fabric_po: {},
      statuses: [
        {
          id: 0,
          text: "Select Active Status",
          value: "",
        },
        { id: "ACTIVE", text: "ACTIVE", value: "1" },
        { id: "INACTIVE", text: "INACTIVE", value: "0" },
      ],
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      total_dispatched: 0,
      pending_meters: 0,
      isLoading: false,
      delete_fabric_po_dispatch: false,
      is_deleted_fabric_po_dispatch: false,
      CurrentFabricType: "",
    };
  },
  watch: {
    page: "getData",
    $route() {
      this.getMasters();
    },
  },
  mounted() {
    this.CurrentFabricType =
      this.$route.name == "FabricSOReports" ? "FABRIC SO" : "FABRIC PO";
    this.getMasters();
  },
  methods: {
    async getMasters() {
      this.CurrentFabricType =
        this.$route.name == "FabricSOReports" ? "FABRIC SO" : "FABRIC PO";
      this.filter.type = this.CurrentFabricType == "FABRIC SO" ? "1" : "0";
      this.fabric_pos = [];
      this.fabricPoItems = [];
      this.fabric_po = {};
      this.pending_meters = "";
      this.next_estimated_delivery = "";
      this.total_dispatched = "";
      let query = "type=" + this.filter.type;
      axios
        .get(`/fabric_po_dispatches/masters?${query}`)
        .then((response) => {
          response.data.fabric_pos.forEach((f) => {
            this.fabric_pos.push(f);
            this.fabricPoItems.push({
              id: f.id,
              text: f.id,
              value: f.id,
            });
          });
          console.log(this.fabric_pos);

          // this.getData();
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    async getData() {
      this.isLoading = true;
      this.filter.search_keyword = this.filter.search_keyword
        ? this.filter.search_keyword
        : "";
      this.filter.is_active = this.filter.is_active
        ? this.filter.is_active
        : "";
      if (this.filter.fabric_po_id) {
        this.fabric_po = this.fabric_pos.find(
          (f) => f.id == this.filter.fabric_po_id
        );
      }

      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&is_active=" +
        this.filter.is_active +
        "&fabric_po_id=" +
        this.filter.fabric_po_id +
        "&search_keyword=" +
        this.filter.search_keyword;
      await axios
        .get(`fabric_po_dispatches?${query}`)
        .then((response) => {
          this.fabric_po_dispatches = response.data.data;
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
          this.Calculate();
          this.NextEstimatedDelivery();
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
    Calculate() {
      this.total_dispatched = 0;
      this.fabric_po_dispatches.forEach((dispatch) => {
        this.total_dispatched += dispatch.meters
          ? parseFloat(dispatch.meters)
          : 0;
      });
      this.pending_meters =
        parseFloat(this.fabric_po.total_quantity) -
        parseFloat(this.total_dispatched);
    },
    NextEstimatedDelivery() {
      let current_date = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      this.fabric_po.fabric_po_delivery_logs.forEach((delivery_log) => {
        // Assuming delivery_log has a property 'estimated_delivery_date' (YYYY-MM-DD format)
        const estimatedDeliveryDate = delivery_log.date;

        // Compare dates
        if (estimatedDeliveryDate < current_date) {
          console.log(
            `Delivery log ID: ${delivery_log.id} has passed its delivery date.`
          );
        } else if (estimatedDeliveryDate === current_date) {
          console.log(
            `Delivery log ID: ${delivery_log.id} has a delivery scheduled for today.`
          );
        } else {
          this.next_estimated_delivery = delivery_log;
        }
      });
    },
    async Delete_this(id) {
      this.delete_fabric_po_dispatch = true;
      await axios
        .post(`/fabric_po_dispatches/delete/${this.encryptIt(id)}`)
        .then(() => {
          this.delete_fabric_po_dispatch = false;
          this.is_deleted_fabric_po_dispatch = true;
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
  },
};
</script>
