<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn
            class="mr-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Add Transportation
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.name"
                outlined
                label="Name *"
                prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.name"
                
                ></v-text-field>
              </v-col>
              <v-col sm="4">
                <v-text-field
                v-model="form.phone"
                outlined
                label="Contact No"
                prepend-inner-icon="mdi-card-text-outline"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.vehicle_no"
                outlined
                label="Vehicle No *"
                prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.gst_no"
                outlined
                label="GST No"
                prepend-inner-icon="mdi-card-text-outline"
              ></v-text-field>
            </v-col>
            <v-col sm="8">
              <v-textarea
                outlined
                rows="2"
                v-model="form.remark"
                label="Remark"
              ></v-textarea>
            </v-col>
            <v-col sm="12">
              <v-textarea
                outlined
                rows="2"
                v-model="form.address"
                label="Address"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <v-btn
            rounded
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="save"
          >
            Save Transportation
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "PjpReferenceCreatePage",
  data() {
    return {
      form: {
        name: "",
      },
      countryItems: [],
      isLoading: false,
    };
  },
  mounted() {},
  methods: {
    async save() {
      try {
        this.isLoading = true;
        await axios
          .post("/transportations", this.form)
          .then(async () => {
            await this.saveUserTimestamp(null, this.form);
            this.isLoading = false;
            this.$router.push("/transportations");
          })
          .catch((error) => {
            // redirect to Home Page
            window.location.href = "/auth/login";
            console.error("Error fetching data:", error);
          });
      } catch (e) {
        // alert("error");
        console.log(e);
        this.isLoading = false;
      }
      this.isLoading = false;
    },
  },
};
</script>
